import React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import "./index.css";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { GetToken, Login } from "../../../Redux/actions/Login";

import { useEffect, useState } from 'react';
import axios from "axios";
const qs = require("qs");

function SignIn() {
  const dispatch = useDispatch();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  let history = useHistory();
  const [show, setShow] = React.useState(false);
  const [emailId, setEmailId] = React.useState();
  const [password, setPassword] = React.useState();
  const handleClick = () => setShow(!show);
  const data = qs.stringify({
    client_id: "szIRcdPGFb9EJxgYWpW/DA==",
    code: "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTAzMjY2MjAsInN1YiI6InJldGFpbGluc2lnaHRzQGdtYWlsLmNvbSIsImlzcyI6Imh0dHBzOi8vdGhlcmV0YWlsaW5zaWdodHMuY29tLyJ9.Fz-OWzf8pumKhQiqf6Qlijw4i_r_za6pi9m9-NU341DHv6P-EoC1WQ73a3a45eyVXZ0amon3UM68TyQIg394lA",
    state: "state",
    grant_type: "authorization_code",
    client_secret: "lcnSFKSdeN1YxZ7ZWIcJ/LpzSmtV9UcduXNjCVZ6qfY=",
    authentication_method: "client_secret_post",
  });

  useEffect(() => {
    console.log("validation ====> ");
    let auth_token = localStorage.getItem('ACCESS_TOKEN');
    if(auth_token){
      console.log("validation ====> auth token found");

      let sendData = qs.stringify({
        client_id: "szIRcdPGFb9EJxgYWpW/DA==",
        code: auth_token,
        state: "state",
        grant_type: "authorization_code",
        client_secret: "lcnSFKSdeN1YxZ7ZWIcJ/LpzSmtV9UcduXNjCVZ6qfY=",
        authentication_method: "client_secret_post",
      });
      dispatch(GetToken(sendData, history));

    }else{
      console.log("validation ====> auth token not found");
    }


    
  },[])


 // console.log("adafd", data);
  const onSigninClick =async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      (emailId === "" || emailId === undefined) &&
      (password === "" || password === undefined)
    ) {
      toast.error("Please Enter Email and Password");
    } else if (emailId === undefined || emailId === "") {
      toast.error("Please Enter Email Id");
    } else if (!emailRegex.test(emailId)) {
      toast.error("Please Enter a valid Email");
    } else if (password === undefined || password === "") {
      toast.error("Please Enter Password");
    } else {

     // debugger

     

     let redirectUri= process.env.REACT_APP_BASE_REDIRECT_URL;
     let clientId= process.env.REACT_APP_BASE_CLIENT_ID;
     let responseType= process.env.REACT_APP_BASE_RESPONSE_TYPE;
     let state= process.env.REACT_APP_BASE_STATE;

     if (!redirectUri || !clientId || !responseType || !state) {
      toast.error("Please Configure The Correct Environment File");
      return;
     }



    //  console.log(redirectUri);  
    //  console.log(clientId);  
    //  console.log(responseType);  
    //  console.log(state);  


    //  return;

      // const clientId = "szIRcdPGFb9EJxgYWpW/DA==";
      // const responseType = "code";
      // const redirectUri = "http://localhost:3000";
      // const state = "state";
      
      const authorizeUri = `https://ri-auth-idp.theretailinsightsdemos.com/auth-idp/api/v1/authorize?` +
        `client_id=${encodeURIComponent(clientId)}&` +
        `redirect_uri=${encodeURIComponent(redirectUri)}&` +
        `response_type=${encodeURIComponent(responseType)}&` +
        `state=${encodeURIComponent(state)}&` +
        `username=${encodeURIComponent(emailId)}&` +
        `password=${encodeURIComponent(password)}`;
      
      console.log(authorizeUri);  
      window.location.href = authorizeUri;
    //  console.log(response.headers);
  
     // window.location.replace(response.url);



   //   console.log("emailpass", emailId, password);
/*       dispatch(Login(emailId, password));
      setTimeout(() => {
        console.log("history ===> ",history);
        dispatch(GetToken(data, history));
      }, 4000); */
    }
  };
  const onEmailChange = (e) => {
    setEmailId(e.target.value);
  };
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };




  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@gmail.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={emailId}
              onChange={onEmailChange}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={password}
                onChange={onPasswordChange}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {/* <Flex justifyContent='flex-end' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex> */}
            <Button
              onClick={onSigninClick}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
