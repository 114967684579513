// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modified-at-date .ant-picker {
  width: 100%;
  height: 43px;
  margin-bottom: 6px;
  border-radius: 13px;
}
.name-edit-container {
  display: flex;
  justify-content: flex-end;
}
.edit-icon {
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
}
.modal-buttons-container {
  display: flex;
  justify-content : flex-end;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/AddApplication/Components/ApplicationDetails.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;AACjB;AACA;EACE,aAAa;EACb,0BAA0B;EAC1B,gBAAgB;AAClB","sourcesContent":[".modified-at-date .ant-picker {\n  width: 100%;\n  height: 43px;\n  margin-bottom: 6px;\n  border-radius: 13px;\n}\n.name-edit-container {\n  display: flex;\n  justify-content: flex-end;\n}\n.edit-icon {\n  margin-left: 10px;\n  font-size: 20px;\n  cursor: pointer;\n}\n.modal-buttons-container {\n  display: flex;\n  justify-content : flex-end;\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
