import React from "react";
import { Select } from "antd";

const SelectDropDown = (props) => {
  return (
    <div>
      <Select
        showSearch={props.showSearch}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onSearch={props.onSearch}
        style={props.style}
        onChange={props.onChange}
        options={props.options}
        allowClear={props.allowClear}
        value={props.value}
      />
    </div>
  );
};

export default SelectDropDown;
