import { useEffect, useState } from "react";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ServiceData } from "Redux/actions/Services/Services";
import "./NavigationMenu.css";
import { EditServiceToggle } from "Redux/actions/Services/Services";
import { selectedServiceId } from "Redux/actions/Services/Services";

const CustomNavigationMenu = () => {
  const dispatch = useDispatch();
  const AppServiceData = useSelector(
    (state) => state?.Service?.serviceApplication?.result?.content
  );
  console.log("AppServiceData",AppServiceData);
  const serviceIdFromStorage = localStorage.getItem("selectedDataId");
  
  const [current, setCurrent] = useState('')
  function getItem(label, key, children, type) {
    return {
      key,
      children,
      label,
      type,
    };
  }
  const [listItem, setListItem] = useState([]);
  console.log("listItem",listItem);
  useEffect(() => {
    if (AppServiceData && AppServiceData?.length > 0) {
      const items = AppServiceData.map((item) => {
        const subItems = item.services.map((service) => {
          return getItem(service.serviceName, service.id);
        });
        return getItem(item.name, item.id, subItems);
      });
      setListItem(items);

      if (AppServiceData && AppServiceData?.length > 0) {
        const defaultSelected = AppServiceData?.[0]?.services?.[0];
        const defaultSelectedKey = AppServiceData?.[0]?.services?.[0]?.id
        setCurrent(defaultSelectedKey)
        dispatch(ServiceData(defaultSelected));
      }
    }
    if (serviceIdFromStorage) {
      const navDetails = (AppServiceData || []).map(item => (item.services || []).filter(child => child.id === parseInt(serviceIdFromStorage))).flat();
      const finalData = navDetails?.[0]
      console.log("filteredData",finalData,serviceIdFromStorage);
      setCurrent(serviceIdFromStorage)
      dispatch(ServiceData(finalData));
    }
  }, [dispatch,AppServiceData,serviceIdFromStorage]);

  // ...
  const rootSubmenuKeys = ["sub1", "sub2", "sub3"];
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  console.log("openKeys",openKeys);
  const onNavChange = (key) => {
    dispatch(selectedServiceId(key))
    console.log("keyValue", key, listItem);
    dispatch(EditServiceToggle(false))
    setCurrent(key)
    // const filteredData = listItem?.map(
    //   (item) => item?.children?.filter((child) => child?.key === parseInt(key))
    // );
    // const filteredData = listItem?.map(
    //   (item) => item?.children?.filter((child) => child?.key === parseInt(key)));
    //   console.log("filteredData:",filteredData);
    // const ServiceLabel = filteredData?.[0]?.[0]?.label;
    // console.log("ServiceLabel:",ServiceLabel);
    // const serviceData = AppServiceData?.filter(
    //   (item) => item?.name === ServiceLabel
    // );
    // const serviceData = AppServiceData?.map((item) => item?.services?.filter((child) => item?.serviceName === ServiceLabel))
    // console.log("serviceData",serviceData);
    // const FilteredData = serviceData?.[0]?.services;
    
    const navDetails = (AppServiceData || []).map(item => (item.services || []).filter(child => child.id === parseInt(key))).flat();
    const finalData = navDetails?.[0]
    console.log("finalData",finalData?.id,navDetails);
    localStorage.setItem("selectedDataId", parseInt(key));
    dispatch(ServiceData(finalData));
  };

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{
        width: 200,
      }}
      selectedKeys={[current]}
      onSelect={({ key }) => onNavChange(key)}
      items={listItem}
    />
  );
};

export default CustomNavigationMenu;
