import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import Card from "components/card/Card.js";
import "./LogDetails.css";
const LogDetails = ({ border, logColumn, logData, cardName }) => {
 // console.log("logColumn", logColumn);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <div>
      <Text
        textAlign="center"
        color={textColor}
        fontSize="md"
        mb="30px"
        fontWeight="600"
        mt="4px"
      >
        {cardName}
      </Text>
      <Card
        border={border}
        p="20px"
        align="center"
        direction="column"
        h="245px"
        w="100%"
      >
        <Flex
          textAlign="center"
          px={{ base: "0px", "2xl": "10px" }}
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          mb="8px"
        >
          <div className="logs-main-container">
            <div className="logs-heads">
              {logColumn.map((label, index) => (
                <p className="log-single-column" key={index}>
                  {label}
                </p>
              ))}
            </div>
            <div className="logs-data">
              {logData.map((label, index) => (
                <p className="log-single-column" key={index}>
                  {label}
                </p>
              ))}
            </div>
          </div>
        </Flex>
      </Card>
    </div>
  );
};

export default LogDetails;
