import axios from "axios";
import * as types from "../actionTypes";

const serviceLogsLoading = () => ({
  type: types.SERVICE_LOGS_LOADING,
});
const serviceLogsSuccess = (data) => ({
  type: types.SERVICE_LOGS_SUCCESS,
  payload: data,
});
const serviceLogsFail = (error) => ({
  type: types.SERVICE_LOGS_FAIL,
  payload: error,
});

export const getServiceLogsByFilter = () => {
  return (dispatch) => {
    dispatch(serviceLogsLoading());
    axios
      .get("https://gateway-qh.theretailinsightsdemos.com/qry/actuator/logfile")
      .then((resp) => {
        dispatch(serviceLogsSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(serviceLogsFail(error));
      });
  };
};
