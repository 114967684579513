// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consumer-gateway-name-container {
    width: 100%;
    display: flex;
    margin-bottom: 30px;   
}
.consumer-division-name {
    width: 60%;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: #1B2559;
}
.gateway-division-name {
    width: 40%;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: #1B2559;
}
.gateway-main-container {
    display: flex;
}
.gateway-data-main-container {
    display: flex;
}
.gateway-main-container {
    display: flex;
}
.container-one {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 21vh;
    border-radius: 20px;
}
.container-two {
    display: flex;
    justify-content: center;
    width: 40%;
    flex-direction: column;
    align-items: center;
}
.container-three {
    display: flex;
    justify-content: center;
    width: 10%;
    align-items: center;
}
.container-four {
    display: flex;
    justify-content: center;
    width: 40%;
    flex-direction: column;
    align-items: center;
}
.arrow-image {
    width: 21px;
}
.configure-text {
    color: #6dcded;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/dataTables/components/ConsumerGatewayLogs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,WAAW;AACf;AACA;IACI,cAAc;AAClB","sourcesContent":[".consumer-gateway-name-container {\n    width: 100%;\n    display: flex;\n    margin-bottom: 30px;   \n}\n.consumer-division-name {\n    width: 60%;\n    text-align: center;\n    font-size: 1rem;\n    font-weight: 600;\n    color: #1B2559;\n}\n.gateway-division-name {\n    width: 40%;\n    text-align: center;\n    font-size: 1rem;\n    font-weight: 600;\n    color: #1B2559;\n}\n.gateway-main-container {\n    display: flex;\n}\n.gateway-data-main-container {\n    display: flex;\n}\n.gateway-main-container {\n    display: flex;\n}\n.container-one {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    width: 10%;\n    height: 21vh;\n    border-radius: 20px;\n}\n.container-two {\n    display: flex;\n    justify-content: center;\n    width: 40%;\n    flex-direction: column;\n    align-items: center;\n}\n.container-three {\n    display: flex;\n    justify-content: center;\n    width: 10%;\n    align-items: center;\n}\n.container-four {\n    display: flex;\n    justify-content: center;\n    width: 40%;\n    flex-direction: column;\n    align-items: center;\n}\n.arrow-image {\n    width: 21px;\n}\n.configure-text {\n    color: #6dcded;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
