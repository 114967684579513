import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import "./ApplicationDetails.css"
import { Button, FormLabel, Input, Text, useColorModeValue } from '@chakra-ui/react';
import SelectDropDown from 'views/admin/Analytics/Components/SelectDropDown';
import { ZoneList } from './ZoneList';
import { DatePicker, Modal, Tooltip } from 'antd';
import { editApplications } from 'Redux/actions/Application';
import { toast } from 'components/Toast/Toast';

const ApplicationDetails = () => {
  const dispatch = useDispatch();
  const applicationList = useSelector((state) => state?.Application?.applications)
  console.log("applicationList",applicationList);
  const initialSelectedApplication = applicationList ?.[0]?.name;
  console.log("initialSelectedApplication",initialSelectedApplication);
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { RangePicker } = DatePicker;

  const [selectedApplcation, setSelectedApplication] = useState(
    initialSelectedApplication
  );
  const [selectedApplcationId, setSelectedApplicationId] = useState(null)
  useEffect(() => {
    if (applicationList && applicationList?.length > 0) {
      setSelectedApplicationId(applicationList?.[0]?.id)
    }
  },[applicationList])

  const applicationDetails = applicationList?.[0];

  console.log("applicationDetails", applicationDetails);

  useEffect(() => {
    if (initialSelectedApplication) {
      setSelectedApplication(initialSelectedApplication);
    }
  }, [initialSelectedApplication]);

  const [editName, setEditName] = useState(false)
  const [selectedDataObject, setSelectedDataObject] = useState({})
  const [name, setName] = useState(null)
  const [access, setAccess] = useState(null)
  const [zone, setZone] = useState(null)
  const [zoneName, setZoneName] = useState(null)
  const [contact, setContact] = useState(null)
  const [serverAccessMode, setServerAccessMode] = useState(null)
  const [afterDateAndTime, setAfterDate] = useState(null)
  const [beforeDateTime, setBeforeDate] = useState(null)
  const [apiObject, setApiObject] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [contactError, setContactError] = useState(false);
  
  console.log("selectedDataObject",selectedDataObject);
  console.log("apiObject",apiObject);
  const selectApplication = (name, id) => {
    setSelectedApplicationId(id)
    console.log("name::",name,id);
    setSelectedApplication(name);
    const selectedData = applicationList?.find((item) => item.name === name)
    console.log("selectedDataselectedData:",selectedData);
    setSelectedDataObject(selectedData)
    setEditName(false)
  };
  
  useEffect(() => {
  if (applicationList?.length > 0 && applicationList) {
  const selectedData = applicationList?.find((item) => item.name === applicationList?.[0]?.name)
  setSelectedDataObject(selectedData)
  }
  else {
  setSelectedDataObject({})
  }
  },[applicationList])

  const accessModeList = [
    {
      label: "AFTER",
      value: "AFTER",
    },
    {
      label: "BEFORE",
      value: "BEFORE",
    },
    {
      label: "BETWEEN",
      value: "BETWEEN",
    },
  ];
  
  const onEditClick = () => {
    setEditName(true)
    setName(selectedDataObject?.name)
    setAccess(selectedDataObject?.access)
    setZone(selectedDataObject?.zone)
    setContact(selectedDataObject?.contact)
    setApiObject({})
  }
  const onCloseHandle = () => {
    setEditName(false)
    setContactError(false)
  }
  const nameUpdateHandle = () => {
    const isObjectEmpty = Object.keys(apiObject).length === 0;
    const checkValidations = contactValidations()
    if (isObjectEmpty === true) {
      toast.error("Please change the data to Update")
      setEditModal(false)
      setContactError(false)
    }
    else if (checkValidations === true) {
      setEditModal(true)
      setContactError(false)
    }
    else {
      setEditModal(false)
      setContactError(true)
    }
  }
  useEffect(() => {
    if (name === selectedDataObject?.name || name === null || name === undefined || name === "") {
      const { name, ...rest } = apiObject;
      setApiObject(rest);
    }
    else {
      setApiObject((prevObject) => ({...prevObject,name}))
    }
  },[name])
  useEffect(() => {
    if (access === selectedDataObject?.access || access === null || access === undefined || access === "") {
      const { access, ...rest } = apiObject;
      setApiObject(rest);
    }
    else {
      setApiObject((prevObject) => ({...prevObject,access}))
    }
  },[access])
  useEffect(() => {
    if (contact === selectedDataObject?.contact || contact === null || contact === undefined || contact === "") {
      const { contact, ...rest } = apiObject;
      setApiObject(rest);
      setContactError(false)
    }
    else {
      setApiObject((prevObject) => ({...prevObject,contact}))
    }
  },[contact])
  useEffect(() => {
    if (zoneName === selectedDataObject?.zone || zoneName === null || zoneName === undefined || zoneName === "") {
      const { zoneName, ...rest } = apiObject;
      setApiObject(rest);
    }
    else {
      setApiObject((prevObject) => ({...prevObject,zoneName}))
    }
  },[zoneName])
  useEffect(() => {
    if (beforeDateTime === null || beforeDateTime === undefined || beforeDateTime === "") {
      const { beforeDateTime, ...rest } = apiObject;
      setApiObject(rest);
    }
    else  {
      setApiObject((prevObject) => ({...prevObject,beforeDateTime}))
    }
  },[beforeDateTime])

  useEffect(() => {
    if (afterDateAndTime === null || afterDateAndTime === undefined || afterDateAndTime === "") {
      const { afterDateAndTime, ...rest } = apiObject;
      setApiObject(rest);
    }
    else  {
      setApiObject((prevObject) => ({...prevObject,afterDateAndTime}))
    }
  },[afterDateAndTime])

  useEffect(() => {
    if (afterDateAndTime === null && beforeDateTime === null) {
      const { afterDateAndTime, beforeDateTime, ...rest } = apiObject;
      setApiObject(rest);
    }
  },[afterDateAndTime,beforeDateTime])
  console.log("apiObject",apiObject);

  const nameHandler = (e) => {
    setName(e.target.value)
  }
  const accessHandler = (e) => {
    setAccess(e.target.value)
  }
  const onZoneSearch = (value, name) => {
    console.log("e.target.value", value);
    setZone(value?.label)
    setZoneName(name?.name)
  };
  const zoneHandler = (value, name) => {
    setZone(value?.label)
    setZoneName(name?.name)
  }
  const accessModeHandler = (value) => {
    console.log("valueadded",value);
    setServerAccessMode(value)
  }
  const contactHandler = (e) => {
    setContact(e.target.value)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(e.target.value)) {
      setContactError(true);
      setContact(null)
    } else {
      setContactError(false);
    }
  }

  const contactValidations = () => {
    if (contact === selectedDataObject?.contact || contact !== "" || contact !== null || contact !== undefined || !contact) {
      setContactError(false);
      return true;
    } else {
      setContactError(true);
      return false;
    }
  };
  const onAfterDateHandler = (dates, dateString) => {
    setAfterDate(dateString)
  }
  const onBeforeDateChange = (dates, dateString) => {
    setBeforeDate(dateString)
  }

  const onEditOk = () => {
    setEditName(false)
    setEditModal(false)
    dispatch(editApplications(selectedApplcationId,apiObject))
    // const selectedData = applicationList?.find((item) => item.id === selectedApplcationId)
    // setSelectedDataObject(selectedData)
  }
  
  const onEditCancel = () => {
    setEditName(true)
    setEditModal(false)
  }
  
  return (
    <div className="service-main-container">
      <div className="service-name-container">
        <p className="service-name">All Applications</p>
        {applicationList?.length > 0 ? 
            applicationList?.map((item) => (
          <p
            key={item?.id}
            className={`service-sub-names ${
              selectedApplcation === item?.name ? "selected" : ""
            }`}
            onClick={() => selectApplication(item?.name,item?.id)}
          >
            {item?.name}
          </p>
        )) : <></>}
      </div>
      <div className="service-break"></div>
      {selectedDataObject ? (
        <div className="service-name-table">
          <div className='name-edit-container'>
          {editName ?
          <Tooltip placement="top" title={"Save Application"}>
          <SaveOutlined onClick={nameUpdateHandle} className='edit-icon'/>
          </Tooltip>
             : applicationList?.length > 0 ?
            <Tooltip placement="top" title={"Edit Application"}>
            <EditOutlined onClick={onEditClick} className='edit-icon' />
            </Tooltip> : <></>
          }
          {editName ? <Tooltip placement="top" title={"Close"}>
          <CloseOutlined onClick={onCloseHandle} className="close-icon" />
          </Tooltip> : <></>}
          </div>
          <p className="selected-service-name">{selectedDataObject?.name}</p>
              <div className="service-container">
                <div className="service-details">
                  <span className="api-label">Name :</span>
                  <span className="api-details">
                    {editName ?  <Input
                    fontSize="sm"
                    type="text"
                    placeholder="Application Name"
                    fontWeight="500"
                    size="lg"
                    maxLength={35}
                    value={name}
                    onChange={nameHandler}
                  /> : selectedDataObject ? selectedDataObject?.name : null}
                  </span>
                </div>

                <div className="service-details">
                  <span className="api-label">Access :</span>
                  <span className="api-details">
                  {editName ?  <Input
                    fontSize="sm"
                    type="text"
                    placeholder="admin"
                    fontWeight="500"
                    size="lg"
                    maxLength={35}
                    value={access}
                    onChange={accessHandler}
                  /> : selectedDataObject ? selectedDataObject?.access : null}
                  </span>
                </div>

                <div className="service-details">
                  <span className="api-label">Zone :</span>
                  <span className="api-details">
                  {editName ?  
                  <SelectDropDown
                  showSearch
                  style={{ width: "100%", marginBottom: "20px" }}
                  placeholder="PLT - Asia/Karachi"
                  value={zone}
                  onSearch={onZoneSearch}
                  onChange={zoneHandler}
                  options={ZoneList}
                ></SelectDropDown>
                : selectedDataObject ? selectedDataObject?.zone : null}
                  </span>
                </div>
              </div>
              <div className="service-container">
                <div className="service-details">
                  <span className="api-label">Server Access Mode :</span>
                  <span className="api-details">
                  {selectedDataObject ? selectedDataObject?.serverAccessMode : null}
                  </span>
                </div>
                <div className="service-details">
                  {selectedDataObject?.serverAccessMode === "BETWEEN" && (
                    <div className="before-after-container">  
                      <div className="service-details">
                        <span className="api-label">ADT:</span>
                        <span className="api-details">
                          {selectedDataObject?.afterDateAndTime}
                        </span>
                      </div>
                      <div className="service-details">
                        <span className="api-label">BDT :</span>
                        <span className="api-details">
                          {selectedDataObject?.beforeDateTime}
                        </span>
                      </div>
                    </div>
                  )}

                  {selectedDataObject?.serverAccessMode === "BEFORE" && (
                    <>
                      <span className="api-label">BDT :</span>
                      <span className="api-details">
                        {selectedDataObject?.beforeDateTime}
                      </span>
                    </>
                  )}

                  {selectedDataObject?.serverAccessMode === "AFTER" && (
                    <>
                      <span className="api-label">ADT :</span>
                      <span className="api-details">
                        {selectedDataObject?.afterDateAndTime}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="service-container">
                <div className="service-details">
                  <span className="api-label">Modified At :</span>
                  <span className="api-details">
                    {selectedDataObject ? selectedDataObject?.updatedAt : null}
                  </span>
                </div>
                <div className="service-details">
                  <span className="api-label">Modified By : </span>
                  <span className="api-details">
                    {selectedDataObject ? selectedDataObject?.updatedBy : null}
                  </span>
                </div>
              </div>

              <div className="service-container">
                <div className="service-details">
                  <span className="api-label">Contact Perosn :</span>
                  <span className="api-details">
                  {editName ?  <Input
                    fontSize="sm"
                    type="text"
                    style={{ width: "66%"}}
                    placeholder="admin@gmail.com"
                    fontWeight="500"
                    size="lg"
                    value={contact}
                    onChange={contactHandler}
                  /> : selectedDataObject ? selectedDataObject?.contact : null}
                  {contactError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter a valid email address
                </p>
              ) : (
                <p></p>
              )}
                  </span>
                </div>
                
                <div className="service-details">
                  <span className="api-label">Created By :</span>
                  <span className="api-details">
                    {selectedDataObject ? selectedDataObject?.createdBy : null}
                  </span>
                </div>
              </div>

              {/* 
              <div className="service-container">
                <div className="service-details">
                  <span className="api-label">Status :</span>
                  <span className="api-details">
                    {selectedDataObject
                      ? selectedDataObject?.active
                        ? "Active"
                        : "In Active"
                      : null}
                  </span>
                </div>
              </div> */}

              {selectedDataObject?.deploymentType === "A" ? (
                <div className="service-container">
                  <div className="service-details">
                    <span className="api-label">CRON Time :</span>
                    <span className="api-details">
                      {selectedDataObject ? selectedDataObject?.cronTime : null}
                    </span>
                  </div>
                  <div className="service-details">
                    <span className="api-label">Every Week Day :</span>
                    <span className="api-details">
                      {selectedDataObject ? selectedDataObject?.everyWeekDay : null}
                    </span>
                  </div>
                </div>
              ) : null}
            
        </div>
      ) : (
        <p className="service-name">Select a application to view details.</p>
      )}
      <div>
      <Modal title="Edit Confirmation" open={editModal} onCancel={onEditCancel}>
        <>
        <div>
          <p>Do you want to Update the Changes?</p>
        </div>
        <div className='modal-buttons-container'>
          <Button onClick={onEditOk}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="15%"
          h="40px"
          mr="10px"
          >Submit</Button>
          <Button onClick={onEditCancel}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="15%"
          h="40px"
          >Cancel</Button>
        </div>
        </>
      </Modal>
        </div>
    </div>
  )
}

export default ApplicationDetails
