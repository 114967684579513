//Toggle Buttons

export const ROUTE_PROXY_ENABLED = "ROUTE_PROXY_ENABLED";
export const COOKIES_ENABLED = "COOKIES_ENABLED";
export const HEADERS_ENABLED = "HEADERS_ENABLED";
export const TIME_DATA_VALUES = "TIME_DATA_VALUES";
export const SERVICE_DATA = "SERVICE_DATA";
export const EDIT_SERVICE_TOGGLE = "EDIT_SERVICE_TOGGLE";
export const SELECTED_SERVICE_ID = "SELECTED_SERVICE_ID";
export const EDIT_API_MODAL_TOGGLE = "EDIT_API_MODAL_TOGGLE";

// LOGIN

export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const TOKEN_LOADING = "TOKEN_LOADING";
export const TOKEN_SUCCESS = "TOKEN_SUCCESS";
export const TOKEN_FAIL = "TOKEN_FAIL";

//DASHBOARD

export const DASHBOARD_METRICS_LOADING = "DASHBOARD_METRICS_LOADING";
export const DASHBOARD_METRICS_SUCCESS = "DASHBOARD_METRICS_SUCCESS";
export const DASHBOARD_METRICS_FAIL = "DASHBOARD_METRICS_FAIL";

export const DASHBOARD_APP_DETAILS_LOADING = "DASHBOARD_APP_DETAILS_LOADING";
export const DASHBOARD_APP_DETAILS_SUCCESS = "DASHBOARD_APP_DETAILS_SUCCESS";
export const DASHBOARD_APP_DETAILS_FAIL = "DASHBOARD_APP_DETAILS_FAIL";

//APPLICATION

export const GET_APPLICATIONS_LOADING = "GET_APPLICATIONS_LOADING";
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_FAIL = "GET_APPLICATIONS_FAIL";

export const CREATE_APPLICATIONS_LOADING = "CREATE_APPLICATIONS_LOADING";
export const CREATE_APPLICATIONS_SUCCESS = "CREATE_APPLICATIONS_SUCCESS";
export const CREATE_APPLICATIONS_FAIL = "CREATE_APPLICATIONS_FAIL";

export const SERVICE_APPLICATION_LOADING = "SERVICE_APPLICATION_LOADING";
export const SERVICE_APPLICATION_SUCCESS = "SERVICE_APPLICATION_SUCCESS";
export const SERVICE_APPLICATION_FAIL = "SERVICE_APPLICATION_FAIL";

export const EDIT_APPLICATION_LOADING = "EDIT_APPLICATION_LOADING";
export const EDIT_APPLICATION_SUCCESS = "EDIT_APPLICATION_SUCCESS";
export const EDIT_APPLICATION_FAIL = "EDIT_APPLICATION_FAIL";

//SERVICES

export const GET_SERVICE_LIST_LOADING = "GET_SERVICE_LIST_LOADING";
export const GET_SERVICE_LIST_SUCCESS = "GET_SERVICE_LIST_SUCCESS";
export const GET_SERVICE_LIST_FAIL = "GET_SERVICE_LIST_FAIL";

export const GET_SERVICE_LOADING = "GET_SERVICE_LOADING";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAIL = "GET_SERVICE_FAIL";

export const EDIT_SERVICE_LOADING = "EDIT_SERVICE_LOADING";
export const EDIT_SERVICE_SUCCESS = "EDIT_SERVICE_SUCCESS";
export const EDIT_SERVICE_FAIL = "EDIT_SERVICE_FAIL";

export const GET_ALL_SERVICE_LOADING = "GET_ALL_SERVICE_LOADING";
export const GET_ALL_SERVICE_SUCCESS = "GET_ALL_SERVICE_SUCCESS";
export const GET_ALL_SERVICE_FAIL = "GET_ALL_SERVICE_FAIL";

export const CREATE_SERVICE_DATA_LOADING = "CREATE_SERVICE_DATA_LOADING";
export const CREATE_SERVICE_DATA_SUCCESS = "CREATE_SERVICE_DATA_SUCCESS";
export const CREATE_SERVICE_DATA_FAIL = "CREATE_SERVICE_DATA_FAIL";

//MANAGE API

export const DEPLOY_API_LOADING = "DEPLOY_API_LOADING";
export const DEPLOY_API_SUCCESS = "DEPLOY_API_SUCCESS";
export const DEPLOY_API_FAIL = "DEPLOY_API_FAIL";

export const CREATE_MANAGE_API_DATA_LOADING = "CREATE_MANAGE_API_DATA_LOADING";
export const CREATE_MANAGE_API_DATA_SUCCESS = "CREATE_MANAGE_API_DATA_SUCCESS";
export const CREATE_MANAGE_API_DATA_FAIL = "CREATE_MANAGE_API_DATA_FAIL";

export const GET_SERVICES_LIST_LOADING = "GET_SERVICES_LIST_LOADING";
export const GET_SERVICES_LIST_SUCCESS = "GET_SERVICES_LIST_SUCCESS";
export const GET_SERVICES_LIST_FAIL = "GET_SERVICES_LIST_FAIL";

export const EDIT_API_LOADING = "EDIT_API_LOADING";
export const EDIT_API_SUCCESS = "EDIT_API_SUCCESS";
export const EDIT_API_FAIL = "EDIT_API_FAIL";

export const TEST_API_LOADING = "TEST_API_LOADING";
export const TEST_API_SUCCESS = "TEST_API_SUCCESS";
export const TEST_API_FAIL = "TEST_API_FAIL";

//SERVICE LOGS

export const SERVICE_LOGS_LOADING = "SERVICE_LOGS_LOADING";
export const SERVICE_LOGS_SUCCESS = "SERVICE_LOGS_SUCCESS";
export const SERVICE_LOGS_FAIL = "SERVICE_LOGS_FAIL";

//DATA LOGS

export const DATA_LOGS_LOADING = "DATA_LOGS_LOADING";
export const DATA_LOGS_SUCCESS = "DATA_LOGS_SUCCESS";
export const DATA_LOGS_FAIL = "DATA_LOGS_FAIL";
export const DATA_LOGS_BY_ID_LOADING = "DATA_LOGS_BY_ID_LOADING";
export const DATA_LOGS_BY_ID_SUCCESS = "DATA_LOGS_BY_ID_SUCCESS";
export const DATA_LOGS_BY_ID_FAIL = "DATA_LOGS_BY_ID_FAIL";
