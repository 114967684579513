// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TimeRangePicker.css */

/* Container styling */
.TimeRangePicker {
  display: flex; /* Display children in a row */
  justify-content: space-between; /* Spread children evenly along the row */
  /* border: 1px solid #ccc; */
  /* padding: 10px; */
  border-radius: 5px;
  width: 60%; /* Adjust the width as needed */
}

/* Button styling */
.TimeRangePicker button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 3px; /* Adjust margin as needed */
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.TimeRangePicker button:hover {
  background-color: #f0f0f0;
}

/* Highlight the selected button */
.TimeRangePicker button.selected {
  background-color: #e0f0ff; /* Light blue color for the selected button */
  font-weight: bold; /* Optionally make the selected button's text bold */
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/Analytics/Components/TimePicker.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB,sBAAsB;AACtB;EACE,aAAa,EAAE,8BAA8B;EAC7C,8BAA8B,EAAE,yCAAyC;EACzE,4BAA4B;EAC5B,mBAAmB;EACnB,kBAAkB;EAClB,UAAU,EAAE,+BAA+B;AAC7C;;AAEA,mBAAmB;AACnB;EACE,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW,EAAE,4BAA4B;EACzC,iBAAiB;EACjB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,kCAAkC;AAClC;EACE,yBAAyB,EAAE,6CAA6C;EACxE,iBAAiB,EAAE,oDAAoD;AACzE","sourcesContent":["/* TimeRangePicker.css */\n\n/* Container styling */\n.TimeRangePicker {\n  display: flex; /* Display children in a row */\n  justify-content: space-between; /* Spread children evenly along the row */\n  /* border: 1px solid #ccc; */\n  /* padding: 10px; */\n  border-radius: 5px;\n  width: 60%; /* Adjust the width as needed */\n}\n\n/* Button styling */\n.TimeRangePicker button {\n  background-color: #fff;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  margin: 3px; /* Adjust margin as needed */\n  padding: 5px 10px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.TimeRangePicker button:hover {\n  background-color: #f0f0f0;\n}\n\n/* Highlight the selected button */\n.TimeRangePicker button.selected {\n  background-color: #e0f0ff; /* Light blue color for the selected button */\n  font-weight: bold; /* Optionally make the selected button's text bold */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
