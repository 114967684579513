// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proxy-toggle-customer {
    display: flex;
    align-items: center;
}
.host-remote-container {
    display: flex;
}
.host-container {
    margin-right: 19px;
}
.host-remote-container {
    margin-top: 10px;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/manageApi/components/CreateApiModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".proxy-toggle-customer {\n    display: flex;\n    align-items: center;\n}\n.host-remote-container {\n    display: flex;\n}\n.host-container {\n    margin-right: 19px;\n}\n.host-remote-container {\n    margin-top: 10px;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
