import React from "react";
import Info from "../../assets/img/info.png";
import { Tooltip } from "antd";

const CustomToolTip = (props) => {
  return (
    <div>
      <Tooltip placement="right" color={"#ffff"} title={props.text}>
        <img className="info-icon" src={Info} alt="info" />
      </Tooltip>
    </div>
  );
};

export default CustomToolTip;
