import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
class OAuth2RedirectHandler extends Component {
    getUrlParameter(name) {
        console.log("url found =====>  ".name,window.location.search);
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    changeWindowLocationAndReplaceHistory = (newLocation) => {
        window.location.replace(newLocation);
    }

    render() {        
        const token = this.getUrlParameter('code');
        const error = this.getUrlParameter('error');
        const state = this.getUrlParameter('state');
        console.log("token found =====>  ",token);
        let auth_token =localStorage.getItem('ACCESS_TOKEN');

        if(token) {

            localStorage.setItem('ACCESS_TOKEN', token);
            console.log("redirect to validation");
            this.changeWindowLocationAndReplaceHistory('/');
            //return <Redirect to="/auth" />; 
            return null; // Return null to prevent rendering anything else
        }else {
            console.log("redirect to auth")
            return <Redirect to="/auth" />; 
        }
    }
}

export default OAuth2RedirectHandler;





