import { Box, Button } from "@chakra-ui/react";
import { FormControl } from "@chakra-ui/react";
import ApplicationDetails from "./Components/ApplicationDetails";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getApplications } from "../../../Redux/actions/Application";
import { useEffect } from "react";

const AddApplication = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  
  const onAddApplication = () => {
    history.push("/admin/new-application");
  };

  useEffect(() => {
    dispatch(getApplications());
    localStorage.removeItem("serviceId");
  }, [dispatch]);
  
  return (
    <Box mt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <FormControl display="flex" justifyContent="flex-end" marginTop="10px">
        <Button
          onClick={onAddApplication}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="15%"
          h="50"
          mb="24px"
        >
          Add Applications
        </Button>
      </FormControl>
      <ApplicationDetails />
    </Box>    
  );
};

export default AddApplication;
