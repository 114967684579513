import React from "react";

// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  const textColors = useColorModeValue("secondaryGray.900", "white");

  return (
    <Flex align="center" direction="column">
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      <Text
        color={textColors}
        fontSize="30px"
        textAlign="start"
        fontWeight="500"
        lineHeight="100%"
        marginBottom="10px"
      >
        API <b>Gateway</b>
      </Text>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
