import { combineReducers } from "redux";
import { ServiceReducer } from "./reducers/Services/Services";
import { ManageApiReducer } from "./reducers/ManageApi";
import { DataLogsReducer } from "./reducers/DataLogs";
import { ApplicationReducer } from "./reducers/Application";
import { ServiceLogsReducer } from "./reducers/ServiceLogs";
import { DashboardReducer } from "./reducers/Dashboard";
import { LoginReducer } from "./reducers/Login";

const rootReducer = combineReducers({
  Login: LoginReducer,
  Dashboard: DashboardReducer,
  Service: ServiceReducer,
  ManageApi: ManageApiReducer,
  DataLogs: DataLogsReducer,
  Application: ApplicationReducer,
  Servicelogs: ServiceLogsReducer,
});

export default rootReducer;
