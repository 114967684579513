// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import "../Analytics.css";
import { useSelector } from "react-redux";

const StatsCard = () => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const appDetailsCompleteData = useSelector(
    (state) => state?.Dashboard?.appDetailsData?.result
  );
  console.log("appDetailsCompleteData", appDetailsCompleteData);
  return (
    <div>
      <Card p="20px" alignItems="center" direction="column" w="100%">
        <Flex
          px={{ base: "0px", "2xl": "10px" }}
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          mb="8px"
        >
          <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
            Stats
          </Text>
        </Flex>
        <div className="stats-main-container">
          <div className="stats-small-container-green">
            <p className="stats-time-green">
              {appDetailsCompleteData?.applications
                ? appDetailsCompleteData?.applications
                : "0"}
            </p>
            <p className="stats-name-green">Applications</p>
          </div>
          <div className="stats-small-container-red">
            <p className="stats-time-red">
              {appDetailsCompleteData?.services
                ? appDetailsCompleteData?.services
                : "0"}
            </p>
            <p className="stats-name-red">Services</p>
          </div>
          <div className="stats-small-container-blue">
            <p className="stats-time-blue">
              {appDetailsCompleteData?.apis
                ? appDetailsCompleteData?.apis
                : "0"}
            </p>
            <p className="stats-name-blue">Api's</p>
          </div>
          <div className="stats-small-container-orange">
            <p className="stats-time-orange">0</p>
            <p className="stats-name-orange">Users</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default StatsCard;
