import {Box,Button,FormControl,FormLabel,Grid,InputGroup,SimpleGrid,Text,useColorModeValue} from "@chakra-ui/react";
import { DatePicker, Input } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SelectDropDown from "views/admin/Analytics/Components/SelectDropDown";
import { createApplications } from "../../../../Redux/actions/Application";
import { ZoneList } from "./ZoneList";
import { toast } from "components/Toast/Toast";

export default function AddApplicationDetails() {
  const accessModeList = [
    {
      label: "AFTER",
      value: "AFTER",
    },
    {
      label: "BEFORE",
      value: "BEFORE",
    },
    {
      label: "BETWEEN",
      value: "BETWEEN",
    },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const { RangePicker } = DatePicker;
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const createApplicationResponse = useSelector((state) => state?.Application?.createApp);
  console.log("createApplicationResponse", createApplicationResponse);

  const addAppSuccess = useSelector((state) => state?.Application?.createApp)
  console.log("addAppSuccess",addAppSuccess);

  const [name, setName] = useState(null);
  const [access, setAccess] = useState(null);
  const [contact, setContact] = useState(null);
  const [serverAccessMode, setAccessMode] = useState(null);
  const [zone, setZone] = useState(null);
  const [zoneName, setZoneName] = useState(null)
  const [afterDateAndTime, setAfterDate] = useState(null);
  const [beforeDateTime, setBeforeDate] = useState(null);

  const [nameError, setNameError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [accessError, setAccessError] = useState(false);
  const [zoneError, setZoneError] = useState(false);
  const [accessModeError, setAccessModeError] = useState(false);
  const [afterDateError, setAfterDateError] = useState(false);
  const [beforeDateError, setBeforeDateError] = useState(false);
  const [betweenDateError, setBetweenDateError] = useState(false);
  useEffect(() => {
    if (createApplicationResponse && Object.keys(createApplicationResponse).length > 0) {
      setName(null);
      setAccess(null);
      setContact(null);
      setZone(null);
      setZoneName(null);
      setAccessMode(null);
      setBeforeDate(null);
      setAfterDate(null);
    }
  }, [createApplicationResponse]);
  
  const nameHandler = (e) => {
    const inputValue = e.target.value;
    setName(inputValue);
    if (/^\s/.test(inputValue)) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };
  const accessHandler = (e) => {
    setAccess(e.target.value);
    const inputValue = e.target.value;
    setAccess(inputValue);
    if (/^\s/.test(inputValue)) {
      setAccessError(true);
    } else {
      setAccessError(false);
    }
  };
  const contactHandler = (e) => {
    setContact(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(e.target.value)) {
      setContactError(true);
    } else {
      setContactError(false);
    }
  };
  const zoneHandle = (value, name) => {
    console.log("value,name", value, name);
    setZone(name?.name);
    setZoneName(name?.label)
    setZoneError(false);
  };
  const onZoneSearch = (value) => {
    console.log("e.target.value", value);
    setZone(value);
  };
  const accessModeHandle = (value) => {
    setAccessMode(value);
    setAccessModeError(false);
  };
  const onModeSearch = (value) => {
    setAccessMode(value);
  };
  const onAfterDateChange = (dates, dateString) => {
    setAfterDate(dateString);
    setAfterDateError(false);
  };
  const onBeforeDateChange = (dates, dateString) => {
    setBeforeDate(dateString);
    setBeforeDateError(false);
  };

  const onBetweenDateChange = (dates, dateString) => {
    if (dates) {
      const formatBeforeDate = dayjs(dates[0]?.$d).format('YYYY-MM-DD');
      const formatAfterDate = dayjs(dates[1]?.$d).format('YYYY-MM-DD');
      setBeforeDate(formatBeforeDate);
      setAfterDate(formatAfterDate);
      setBetweenDateError(false);
    }
    else if (dates === null) {
      setBeforeDate(null);
      setAfterDate(null);
    }
  };

  const nameValidations = () => {
    if (name !== "" && name !== null && name !== undefined) {
      setNameError(false);

      return true;
    } else {
      setNameError(true);
      return false;
    }
  };
  const contactValidations = () => {
    if (contact !== "" && contact !== null && contact !== undefined && !contactError) {
      setContactError(false);
      return true;
    } else {
      setContactError(true);
      return false;
    }
  };

  const accessValidations = () => {
    if (access !== "" && access !== null && access !== undefined) {
      setAccessError(false);
      return true;
    } else {
      setAccessError(true);
      return false;
    }
  };
  console.log("accessaccess",access,);

  const zoneValidations = () => {
    if (zone !== "" && zone !== null && zone !== undefined) {
      setZoneError(false);
      return true;
    } else {
      setZoneError(true);
      return false;
    }
  };
  const accessModeValidations = () => {
    if (serverAccessMode !== "" && serverAccessMode !== null && serverAccessMode !== undefined) {
      setAccessModeError(false);

      return true;
    } else {
      setAccessModeError(true);
      return false;
    }
  };

  const afterDateValidations = () => {
    if (afterDateAndTime !== "" && afterDateAndTime !== null && afterDateAndTime !== undefined) {
      setAfterDateError(false);

      return true;
    } else {
      setAfterDateError(true);
      return false;
    }
  };
  const beforeDateValidations = () => {
    if (beforeDateTime !== "" && beforeDateTime !== null && beforeDateTime !== undefined) {
      setBeforeDateError(false);

      return true;
    } else {
      setBeforeDateError(true);
      return false;
    }
  };
  const betwwenDateValidations = () => {
    if (access !== "" && access !== null && access !== undefined) {
      setBetweenDateError(false);
      return true;
    } else {
      setBetweenDateError(true);
      return false;
    }
  };

  const checkValidations = () => {
    // console.log("isChecked");
    if (
      (nameValidations() === true) &
      // (ModifiedByValidations() === true) &
      (accessValidations() === true) &
      (contactValidations() === true) &
      // (ModifiedAtValidations() === true) &
      (zoneValidations() === true) &
      (accessModeValidations() === true) &
      ((afterDateValidations() === true) ||
      (beforeDateValidations() === true))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [apiObject, setApiObject] = useState({})
  
  useEffect(() => {
    if (beforeDateTime === null || beforeDateTime === undefined || beforeDateTime === "") {
      const { beforeDateTime, ...rest } = apiObject;
      setApiObject(rest);
    }
    else  {
      setApiObject((prevObject) => ({...prevObject,beforeDateTime}))
    }
  },[beforeDateTime])
  console.log("apiObject",apiObject);

  useEffect(() => {
    if (afterDateAndTime === null || afterDateAndTime === undefined || afterDateAndTime === "") {
      const { afterDateAndTime, ...rest } = apiObject;
      setApiObject(rest);
    }
    else  {
      setApiObject((prevObject) => ({...prevObject,afterDateAndTime}))
    }
  },[afterDateAndTime])

  useEffect(() => {
    if (afterDateAndTime === null && beforeDateTime === null) {
      const { afterDateAndTime, beforeDateTime, ...rest } = apiObject;
      setApiObject(rest);
    }
  },[afterDateAndTime,beforeDateTime])

  useEffect(() => {
    if (name) {
      setApiObject((prevObject) => ({...prevObject,name}))
    }
    if (access) {
      setApiObject((prevObject) => ({...prevObject,access}))
    }
    if (contact) {
      setApiObject((prevObject) => ({...prevObject,contact}))
    }
    if (zone) {
      setApiObject((prevObject) => ({...prevObject,zone}))
    }
    if (serverAccessMode) {
      setApiObject((prevObject) => ({...prevObject,serverAccessMode}))
    }
  },[name,access,contact,zone,serverAccessMode])

  const submitHandler = (e) => {
    const value = checkValidations();
    if (value === true) {
      dispatch(createApplications(apiObject));
    }
    else {
      toast.error("Couldn't create Application")
    }
  };
  const onBackHandle = () => {
    history.push("/admin/add-application");
  };
  return (
    <Box mt={{ base: "300px", md: "80px", xl: "80px" }}>
      <p className="back-button" onClick={onBackHandle}>
        &lt;Back
      </p>
      <Box
        backgroundColor={"white"}
        borderRadius={"20px"}
        // pt={{ base: "180px", md: "80px", xl: "80px" }}
      >
        {/* Main Fields */}
        <Grid
          mb="20px" 
          // gridTemplateColumns={{ xl: "repeat(1, 1fr)", "2xl": "1fr 0.46fr" }}
          gap={{ base: "20px", xl: "20px" }}
          display={{ base: "block", xl: "grid" }}
        >
          <SimpleGrid
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: "10px", xl: "10px" }}
          >
            <FormControl padding={"20px"} borderRadius={"20px"}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                maxLength={30}
                type="text"
                placeholder="Application Name"
                className="common-input-fields"
                value={name}
                onChange={nameHandler}
              />
              {nameError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter name
                </p>
              ) : (
                <p></p>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="10px"
              >
                Access<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  maxLength={50}
                  fontSize="sm"
                  placeholder="admin"
                  mb="24px"
                  size="lg"
                  type={"text"}
                  variant="auth"
                  className="common-input-fields"
                  value={access}
                  onChange={accessHandler}
                />
              </InputGroup>
              {accessError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter Access
                </p>
              ) : (
                <p></p>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="10px"
              >
                Contact<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="admin@gmail.com"
                  mb="24px"
                  size="lg"
                  type={"text"}
                  maxLength={50}
                  variant="auth"
                  className="common-input-fields"
                  value={contact}
                  onChange={contactHandler}
                />
              </InputGroup>
              {contactError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter a valid email address
                </p>
              ) : (
                <p></p>
              )}
            </FormControl>
            <FormControl padding={"20px"}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Zone<Text color={brandStars}>*</Text>
              </FormLabel>

              <SelectDropDown
                showSearch
                style={{ width: "100%", marginBottom: "20px" }}
                placeholder="PLT - Asia/Karachi"
                value={zoneName}
                onSearch={onZoneSearch}
                onChange={zoneHandle}
                options={ZoneList}
              ></SelectDropDown>
              {zoneError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please Select Zone
                </p>
              ) : (
                <p></p>
              )}

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Server Access Mode<Text color={brandStars}>*</Text>
              </FormLabel>
              <SelectDropDown
                showSearch
                style={{ width: "100%", marginBottom: "20px" }}
                onChange={accessModeHandle}
                onSearch={onModeSearch}
                placeholder={"Select Server Access Mode"}
                options={accessModeList}
                value={serverAccessMode}
              />
              {accessModeError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please Select Server Access Mode
                </p>
              ) : (
                <p></p>
              )}

              {serverAccessMode === "AFTER" ? (
                <>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    ADT<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div className="modified-at-date">
                    <DatePicker
                      placeholder="09/10/2023"
                      // value={afterDate}
                      onChange={onAfterDateChange}
                    />{" "}
                  </div>
                  {afterDateError ? (
                    <p
                      style={{
                        color: "red",
                        marginBottom: "5px",
                        fontSize: "11px",
                      }}
                    >
                      ** Please Select After Date
                    </p>
                  ) : (
                    <p></p>
                  )}
                </>
              ) : serverAccessMode === "BEFORE" ? (
                <>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    BDT<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div className="modified-at-date">
                    <DatePicker
                      placeholder="09/10/2023"
                      // value={beforeDate}
                      onChange={onBeforeDateChange}
                    />{" "}
                  </div>
                  {beforeDateError ? (
                    <p
                      style={{
                        color: "red",
                        marginBottom: "5px",
                        fontSize: "11px",
                      }}
                    >
                      ** Please Select Before Date
                    </p>
                  ) : (
                    <p></p>
                  )}
                </>
              ) : serverAccessMode === "BETWEEN" ? (
                <>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Between Date & Time<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div className="modified-at-date">
                    <RangePicker
                      // value={rangeValue}
                      onChange={onBetweenDateChange}
                    />{" "}
                  </div>
                  {betweenDateError ? (
                    <p
                      style={{
                        color: "red",
                        marginBottom: "5px",
                        fontSize: "11px",
                      }}
                    >
                      ** Please Select Between Date
                    </p>
                  ) : (
                    <p></p>
                  )}
                </>
              ) : (
                <></>
              )}
            </FormControl>
          </SimpleGrid>
          <FormControl display="flex" justifyContent="center" marginTop="10px">
            <Button
              //   onClick={onSigninClick}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="15%"
              h="50"
              mb="24px"
              onClick={submitHandler}
            >
              Submit
            </Button>
          </FormControl>
          {/* <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
        >
          <FormControl
            background={"white"}
            padding={"20px"}
            borderRadius={"20px"}
          >
            <Text
              fontSize="24px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="10px"
            >
              Properties
            </Text>
            <br />
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <FormLabel
                  display="flex"
                  ms="4px"
                  w="100px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Route Proxy
                </FormLabel>
                <Checkbox
                  onChange={onRoutProxyEnabled}
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                  value={routeProxyToggle}
                />
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <FormLabel
                  display="flex"
                  ms="4px"
                  w="100px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Cookies
                </FormLabel>
                <Checkbox
                  onChange={onCookiesEnabled}
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                  value={cookiesToggle}
                />
              </FormControl>
            </Flex>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <FormLabel
                  display="flex"
                  ms="4px"
                  w="100px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Headers
                </FormLabel>
                <Checkbox
                  onChange={onHeadersEnabled}
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                  value={headersToggle}
                />
              </FormControl>
            </Flex>
          </FormControl>
        </Flex> */}
        </Grid>
      </Box>
    </Box>
  );
}
