import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ScatteredChart from "./ScatteredChart";
import { Loader } from "./Loader/Loader";
import { Box } from "@chakra-ui/react";

const ScatteredChartWithLoader = () => {
  const [loaderToggle, setLoaderToggle] = useState(true);
  const metricsData = useSelector((state) => state?.Dashboard?.metrics);

  useEffect(() => {
    setLoaderToggle(true);
    setTimeout(() => {
      setLoaderToggle(false);
    }, 2000);
  }, [metricsData]);
  return (
    <>
      {loaderToggle ? (
        <Box
          w={"100%"}
          backgroundColor={"white"}
          p={"20px"}
          height={"400px"}
          borderRadius={"10px"}
        >
          <Loader />
        </Box>
      ) : (
        <ScatteredChart />
      )}
    </>
  );
};

export default ScatteredChartWithLoader;
