import axios from "axios";
import * as types from "../actionTypes";
import { toast } from "components/Toast/Toast";

const loginLoading = () => ({
  type: types.LOGIN_LOADING,
});
const loginSuccess = (data) => ({
  type: types.LOGIN_SUCCESS,
  payload: data,
});
const loginFail = (error) => ({
  type: types.LOGIN_FAIL,
  payload: error,
});
const tokenLoading = () => ({
  type: types.TOKEN_LOADING,
});
const tokenSuccess = (data) => ({
  type: types.TOKEN_SUCCESS,
  payload: data,
});
const tokenFail = (error) => ({
  type: types.TOKEN_FAIL,
  payload: error,
});

let url = process.env.REACT_APP_BASE_URL_GET;
let postUrl = process.env.REACT_APP_BASE_URL_POST;



const authInstance = axios.create({
  // ... other Axios configuration options
});

authInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 302) {
      // Assuming the authorization server redirects with status 302
      const redirectUrl = error.response.headers.location;
      window.location.href = redirectUrl; // Redirect the browser
    }
    return Promise.reject(error);
  }
);


export const Login =  (username, password) => {
  console.log("username", username, password);
  return async (dispatch) => {
    dispatch(loginLoading());
    try {
      const response = await authInstance.get(
        `https://ri-auth-idp.theretailinsightsdemos.com/auth-idp/api/v1/authorize?client_id=szIRcdPGFb9EJxgYWpW/DA==&redirect_uri=http://localhost:3000&response_type=code&state=state&username=${username}&password=${password}`
      );
      // This wouldn't be reached due to redirection
      console.log(response.data);
    } catch (error) {
      console.error(error);
      // Handle other potential errors
    }

/*     axios
    .get(`https://ri-auth-idp.theretailinsightsdemos.com/auth-idp/api/v1/authorize?client_id=szIRcdPGFb9EJxgYWpW/DA==&redirect_uri=http://localhost:3000&response_type=code&state=state&username=${username}&password=${password}`)
    .then(resp => {
      // Extract the redirect URL from the response
      const redirectUrl = resp.request.responseURL;

      // Extract code from the redirect URL
      const codeRegex = /[?&]code=([^&]+)/;
      const codeMatch = redirectUrl.match(codeRegex);
      const codeValue = codeMatch ? codeMatch[1] : '';

      if (codeValue){
        // Call another API endpoint to exchange code for token
        return axios.get(`https://ri-auth-idp.theretailinsightsdemos.com/auth-idp/api/v1/token?client_id=szIRcdPGFb9EJxgYWpW/DA==&redirect_uri=http://localhost:3000&grant_type=authorization_code&code=${codeValue}`);
      } else {
        throw new Error('Authentication Token not Found in URL.');
      }
    })
    .then(tokenResp => {
      // Dispatch login success action with token
      dispatch(loginSuccess(tokenResp.data.access_token));
    })
    .catch(error => {
      dispatch(loginFail(error.message));
    }); */
  };
};
export const GetToken = (data, history) => {
  return (dispatch) => {
    dispatch(tokenLoading());
    axios
      .post(
        `https://ri-auth-idp.theretailinsightsdemos.com/auth-idp/api/v1/token`,
        data
      )
      .then((resp) => {
        dispatch(tokenSuccess(resp?.data));
        localStorage.removeItem('ACCESS_TOKEN');
        history.push("/admin/default");
        toast.success("Logged In Successfully");
      })
      .catch((error) => {
        dispatch(tokenFail(error));
      });
  };
};


