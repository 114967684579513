import { Box } from "@chakra-ui/react";
import { getServiceLogsByFilter } from "Redux/actions/ServiceLogs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './index.css'
import { Loader } from "../AddApplication/Components/Loader/Loader";
const ServiceLogs = () => {
  const dispatch = useDispatch()

  const [loaderToggle ,setLoaderToggle] = useState(true)
  const ServiceLogsData = useSelector((state) => state?.Servicelogs?.serviceLogs)
  console.log("ServiceLogsData",typeof ServiceLogsData)
  useEffect(() => {
   dispatch(getServiceLogsByFilter())
  }, [dispatch])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' for an instant scroll or 'smooth' for a smooth scroll animation.
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoaderToggle(false)
    },2000)
  },[ServiceLogsData])

  return (
    <>
      {
        loaderToggle ? <Loader /> : 
        <Box backgroundColor={"white"} mt={{ base: "180px", md: "80px", xl: "80px" }} p={"20px"} borderRadius={"20px"}>
        {Object.keys(ServiceLogsData).length !== 0 ? <pre>{JSON.stringify(ServiceLogsData, null, 2)}</pre> : <></>}
        </Box>    
      }
      </>
  );
};

export default ServiceLogs;
