import Chart from "chart.js/auto";

export const Utils = {
  CHART_COLORS: {
    red: "rgb(255, 99, 132, 0.8)",
    blue: "rgb(54, 162, 235, 0.8)",
    green: "rgb(75, 192, 192, 0.8)",
    yellow: "rgb(255, 205, 86, 0.8)",
    grey: "rgb(201, 203, 207, 0.9)",
    purple: "rgb(153, 102, 255)",
    pink: "rgb(188, 83, 139)",
    orange: "rgb(188, 121, 58)",
  },
  transparentize(color, opacity) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return Chart.helpers?.color(color).alpha(alpha).rgbString();
  },
  points(config) {
    // const count = config.count;

    return;
  },
  // randomScalingFactor() {
  //   return Math.round(Math.random() * (3000 - 100) + 100);
  // },
};
