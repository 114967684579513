import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

function LineChart() {
  const datadata = useSelector((state) => state?.Dashboard?.metrics);
  console.log("datadata", datadata);
  const LineChartData = useSelector(
    (state) => state?.Dashboard?.metrics[0]?.monthlyHitsCount[0]
  );
  console.log("LineChartData", LineChartData?.series[0]?.data);
  const xAxisData = LineChartData?.options?.x_axis?.map((item) => {
    return item;
  });
  const yAxisData = LineChartData?.series[0]?.data.map((item) => {
    return item;
  });

  console.log("xAxisData", xAxisData, yAxisData);
  const [chartState] = useState({
    series: [
      {
        name: LineChartData?.series[0]?.label,
        data: yAxisData,
      },
    ],

    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: LineChartData?.options?.title,
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: xAxisData
          ? xAxisData
          : [
              "JAN",
              "FEB",
              "MAR",
              "APR",
              "MAY",
              "JUN",
              "JUL",
              "AUG",
              "SEP",
              "OCT",
              "NOV",
              "DEC",
            ],
      },
    },
  });

  return (
    <Box backgroundColor={"white"} p={"10px"} borderRadius={"10px"}>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="line"
          height={350}
        />
      </div>
    </Box>
  );
}

export default LineChart;
