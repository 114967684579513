import { SimpleGrid, Box } from "@chakra-ui/react";
import LogDetails from "../components/LogDetails";
import ConsumerGatewayLogs from "../components/ConsumerGatewayLogs";
import RightArrow from "../components/Images/right-arrow.png";
import LeftArrow from "../components/Images/left-arrow.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const Logs = () => {
  const history = useHistory();
  const logByIdData = useSelector((state) => state?.DataLogs?.dataLogsByID);
  const logRequestColumn = Object.keys(logByIdData?.requestDetails).map(
    (key) => key.charAt(0).toUpperCase() + key.slice(1)
  );
  const logRequestData = Object.values(logByIdData?.requestDetails);
  const logResponseColumn = Object.keys(logByIdData?.responseDetails).map(
    (key) => key.charAt(0).toUpperCase() + key.slice(1)
  );
  const logResponseData = Object.values(logByIdData?.responseDetails);
  const onBackHandle = () => {
    history.push("/admin/data-logs");
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' for an instant scroll or 'smooth' for a smooth scroll animation.
    });
  }, []);
  return (
    <Box mt={{ base: "300px", md: "80px", xl: "80px" }}>
      <p className="back-button" onClick={onBackHandle}>
        &lt;Back
      </p>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
        <LogDetails
          border="2px solid #a1e7fd"
          logColumn={logRequestColumn}
          logData={logRequestData}
          cardName="REQUEST"
        />
        <LogDetails
          border="2px solid #bfdfc5"
          logColumn={logResponseColumn}
          logData={logResponseData}
          cardName="RESPONSE"
        />
      </SimpleGrid>
      <br />
      <br />
      <br />
      <div className="consumer-gateway-name-container">
        <p className="consumer-division-name">CONSUMER</p>
        {/* <Text textAlign="center" color={textColor} fontSize='md' mb='30px' fontWeight='600' mt='4px'>
        </Text> */}
        <p className="gateway-division-name">GATEWAY</p>
      </div>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px">
        <ConsumerGatewayLogs
          Imagesrc={RightArrow}
          smallCardName="REQUEST"
          smallCardBgColor="#a1e7fd"
          backgroundColor="#dbf6fe"
        />
        <ConsumerGatewayLogs
          Imagesrc={LeftArrow}
          smallCardName="RESPONSE"
          smallCardBgColor="#d8eec9"
          backgroundColor="#eff8e9"
        />
      </SimpleGrid>
    </Box>
  );
};

export default Logs;
