export const ZoneList = [
  {
    label: "Australia/Darwin",
    value: "australia/Darwin",
    name: "ACT",
  },
  {
    label: "Australia/Sydney",
    value: "australia/Sydney",
    name: "AET",
  },
  {
    label: "America/Argentina/Buenos_Aires",
    value: "america/Argentina/Buenos_Aires",
    name: "AGT",
  },
  {
    label: "Africa/Cairo",
    value: "africa/Cairo",
    name: "ART",
  },
  {
    label: "America/Anchorage",
    value: "america/Anchorage",
    name: "AST",
  },
  {
    label: "America/Sao_Paulo",
    value: "america/Sao_Paulo",
    name: "BET",
  },
  {
    label: "Asia/Dhaka",
    value: "asia/Dhaka",
    name: "BST",
  },
  {
    label: "Africa/Harare",
    value: "africa/Harare",
    name: "CAT",
  },
  {
    label: "America/St_Johns",
    value: "america/St_Johns",
    name: "CNT",
  },
  {
    label: "America/Chicago",
    value: "america/Chicago",
    name: "CST",
  },
  {
    label: "Asia/Shanghai",
    value: "asia/Shanghai",
    name: "CTT",
  },
  {
    label: "Africa/Addis_Ababa",
    value: "africa/Addis_Ababa",
    name: "EAT",
  },
  {
    label: "Europe/Paris",
    value: "europe/Paris",
    name: "ECT",
  },
  {
    label: "America/Indiana/Indianapolis",
    value: "america/Indiana/Indianapolis",
    name: "IET",
  },
  {
    label: "Asia/Kolkata",
    value: "asia/Kolkata",
    name: "IST",
  },
  {
    label: "Asia/Tokyo",
    value: "asia/Tokyo",
    name: "JST",
  },
  {
    label: "Pacific/Apia",
    value: "pacific/Apia",
    name: "MIT",
  },
  {
    label: "Asia/Yerevan",
    value: "asia/Yerevan",
    name: "NET",
  },
  {
    label: "Pacific/Auckland",
    value: "pacific/Auckland",
    name: "NST",
  },
  {
    label: "Asia/Karachi",
    value: "asia/Karachi",
    name: "PLT",
  },
  {
    label: "America/Phoenix",
    value: "america/Phoenix",
    name: "PNT",
  },
  {
    label: "America/Puerto_Rico",
    value: "america/Puerto_Rico",
    name: "PRT",
  },
  {
    label: "America/Los_Angeles",
    value: "america/Los_Angeles",
    name: "PST",
  },
  {
    label: "Pacific/Guadalcanal",
    value: "pacific/Guadalcanal",
    name: "SST",
  },
  {
    label: "Asia/Ho_Chi_Minh",
    value: "asia/Ho_Chi_Minh",
    name: "VST",
  },
];
