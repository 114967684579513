import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
// import RtlLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { Provider } from "react-redux";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import store from "./Redux/store";
import { Toast } from "components/Toast/Toast";
import registerServiceWorker from "./registerServiceWorker";
import OAuth2RedirectHandler from "layouts/oauth2";



ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <ThemeEditorProvider>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            {/* <Route path={`/rtl`} component={RtlLayout} /> */}
           {/*  <Redirect from="/" to="/auth" /> */}
            <Route exact path="/" component={OAuth2RedirectHandler}></Route>

          </Switch>
        </HashRouter>
        <Toast />
      </ThemeEditorProvider>
    </ChakraProvider>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();