// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Poppins";
}

option {
  color: black;
}
.ant-modal .ant-modal-footer {
  display: none;
}
span .ant-select .ant-select-arrow {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  height: 12px;
  margin-top: 0px !important;
  font-size: 12px;
  pointer-events: none;
}
.ant-select .ant-select-clear {
  margin-top: 0px !important;
}

span.ant-select-arrow {
  margin-top: 0px !important;
}

.common-input-fields {
  font-weight: 500;
  padding: 10px;
}
.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,0BAA0B;EAC1B,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,oBAAoB;EACpB,wBAAwB;EACxB,kCAAkC;EAClC,mCAAmC;EACnC,kCAAkC;EAClC,kBAAkB;EAClB,QAAQ;EACR,wBAAwB;EACxB,sBAAsB;EACtB,YAAY;EACZ,0BAA0B;EAC1B,eAAe;EACf,oBAAoB;AACtB;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap\");\n* {\n  font-family: \"Poppins\";\n}\n\noption {\n  color: black;\n}\n.ant-modal .ant-modal-footer {\n  display: none;\n}\nspan .ant-select .ant-select-arrow {\n  display: flex;\n  align-items: center;\n  color: rgba(0, 0, 0, 0.25);\n  font-style: normal;\n  line-height: 1;\n  text-align: center;\n  text-transform: none;\n  vertical-align: -0.125em;\n  text-rendering: optimizeLegibility;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  position: absolute;\n  top: 50%;\n  inset-inline-start: auto;\n  inset-inline-end: 11px;\n  height: 12px;\n  margin-top: 0px !important;\n  font-size: 12px;\n  pointer-events: none;\n}\n.ant-select .ant-select-clear {\n  margin-top: 0px !important;\n}\n\nspan.ant-select-arrow {\n  margin-top: 0px !important;\n}\n\n.common-input-fields {\n  font-weight: 500;\n  padding: 10px;\n}\n.ant-menu-light.ant-menu-root.ant-menu-inline {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
