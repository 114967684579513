import { Box, SimpleGrid ,Button} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import refreshIcon from '../Analytics/Components/Images/refresh-page-option.png';
import RangePickerFromTo from "../Analytics/Components/RangePickerFromTo";
import SelectDropDown from "../Analytics/Components/SelectDropDown";
import TimeRangePicker from "../Analytics/Components/TimePicker";
import Input from "./components/Input";
import "./index.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataLogsById } from "Redux/actions/DataLogs";
import { getDataLogsByFilter } from "Redux/actions/DataLogs";
import { Loader } from "components/Loader/Loader";
import CustomDropDown from "../manageApi/components/CustomDropDown";
import { getApplications } from "Redux/actions/Application";
import { toast } from "components/Toast/Toast";

export default function Settings() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [method,setMethod] = useState(null)
  const [application,setApplication] = useState(null)
  const [requestId,setREquestId] = useState()
  const [path,setPath] = useState()
  const [endpoint,setEndpoint] = useState()
  const [statusCode,setStatusCode] = useState(null)
  const [applicationListData,setApplicationListData] = useState([])
  
  const logsData = useSelector((state) => state?.DataLogs?.dataLogs?.content)
  console.log("logsData",logsData);
  const applicationList = useSelector((state) => state?.Application?.applications?.result)
  console.log("applicationList",applicationList);
  const timeUnits = useSelector((state) => state?.DataLogs?.timePickerUnits)
  const timeMeasure = useSelector((state) => state?.DataLogs?.timePickerMeasure)
  console.log("timePickerValueData",timeUnits,timeMeasure);

  const AllOptions = [
    {
      value: 'All',
      label: 'All',
      name: 'All',
    },
  ]
  const MethodList = [
    {
     label : 'GET',
     value: 'GET',
    },
    {
     label : 'POST',
      value: 'POST',
    },
    {
     label : 'PUT',
      value: 'PUT',
    },
    {
     label : 'PATCH',
      value: 'PATCH',
    },
    {
     label : 'DELETE',
      value: 'DELETE',
    },
  ]
  const StatusList = [
    {
     label : '200',
     value: '200',
    },
    {
     label : '201',
      value: '201',
    },
    {
     label : '302',
      value: '302',
    },
    {
     label : '400',
      value: '400',
    },
    {
     label : '500',
      value: '500',
    },
    {
     label : '502',
      value: '502',
    },
    {
     label : '503',
      value: '503',
    },
  ]
  const refreshOptions = [
    {
      label: 'Auto-refresh disabled',
      value: 'Auto-refresh disabled',
      name: 'disabled',
    },
    {
      label: 'Auto-refresh enabled',
      value: 'Auto-refresh enabled',
      name: 'enabled',
    },
  ]   

   const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Application',
      dataIndex: 'application',
    },
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
    },
    {
      title: 'Method',
      dataIndex: 'method',
    },
    {
      title: 'Path',
      dataIndex: 'path',
    },
  ];

  useEffect(() => {
    dispatch(getApplications());
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' for an instant scroll or 'smooth' for a smooth scroll animation.
    });
    localStorage.removeItem("serviceId");
  }, []);
  useEffect(() => {
    if (applicationList && applicationList.length > 0) {
      const mappedApplications = applicationList?.map((item) => {
        return {
          label: item.name,
          value: item.name,
          name : item.id,
        }
      })
      setApplicationListData(mappedApplications)
      console.log("mappedApplications",mappedApplications);
    }
  },[applicationList])

  const tableData = [];
    logsData?.map((item, index) => {
      return tableData.push({
        key: index,
        id: `${item?.logId}`,
        date: `${item?.date}`,
        status: <p className={`status-${item?.statusCode === "205" ? 200 : item?.statusCode <= "305" ?  300 : item?.statusCode <= "405" ? 400 : 500}-code`}>{item?.statusCode}</p>,
        application: `${item?.app}`,
        serviceName: `${item?.serviceName}`,
        method: <p className={`method-${["POST", "GET", "DELETE", "PATCH", "PUT"].includes(item?.method?.toUpperCase()) ? item?.method?.toLowerCase() : ""}`}>{item?.method}</p>,
        path: `${item?.path}`,
      })
    })
  

  const [loaderToggle,setLoaderToggle] = useState(false)

  const onRowClick = (record, rowIndex) => {
    setLoaderToggle(true)
    console.log("record, rowIndex",record, rowIndex);
    console.log("record, rowIndex",record, rowIndex)
    dispatch(getDataLogsById(record?.id))
    setTimeout(() => {
      history.push("/admin/logs")
      setLoaderToggle(false)
    },2000)
  }
  const onRequestIdChange = (e) => {
    setREquestId(e.target.value)
    console.log('onRequestIdChange',e.target.value)
  }
  const onPathChange = (e) => {
    setPath(e.target.value)
    console.log('onPathChange',e.target.value)
  }
  const onEndpointChange = (e) => {
    setEndpoint(e.target.value)
    console.log('onEndpointChange',e.target.value)
  }
  const onMethodChange = (value,label) => {
    setMethod(label?.name)
    console.log('onMethodChange',label?.name)
  }
  const onApplicationChange = (value,label) => {
    setApplication(value)
    console.log('onApplicationChange',value,label)
  }
  const onRangePickerChange = (dates,dateString) => {
   console.log("dtatetete",dates,dateString)
  }

  const [apiObject, setApiObject] = useState({})

  useEffect(() => {
    if (method === null || method === undefined || method === "") {
      const { method, ...rest } = apiObject;
      setApiObject(rest);
    }
    else  {
      setApiObject((prevObject) => ({...prevObject,method}))
    }
  },[method])

    useEffect(() => {
      if (statusCode === null || statusCode === undefined || statusCode === "")  {
        const { statusCode, ...rest } = apiObject;
        setApiObject(rest);
      }
      else {
        setApiObject((prevObject) => ({...prevObject,statusCode}))
      }
    },[statusCode])
    
    useEffect(() => {
      if (application === null || application === undefined || application === "") {
        const { application, ...rest } = apiObject;
        setApiObject(rest);
      }
      else {
        setApiObject((prevObject) => ({...prevObject,application}))
      }
    },[application])


  console.log("apiObject:",apiObject,method);
  const onSearchClick = () => {
    let apiData = {
      "unit": timeUnits,
      "unitMeasure": timeMeasure, 
      "filters" : apiObject
    }

  if (method || statusCode || application) {
    dispatch(getDataLogsByFilter(apiData))
  }
  else {
    toast.error("Please select the filter to search")
  }
  }
  const onStatusChange = (value) => {
    setStatusCode(value)
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loaderToggle ? <Loader /> : null}
      <SimpleGrid justifyContent={"flex-end"} marginBottom={"20px"}>
        <Button  fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="43px"
          mt="1px">Export as CSV
        </Button>
      </SimpleGrid>
      <SimpleGrid backgroundColor={"white"} marginBottom={"20px"} padding={"20px"} borderRadius={"20px"}>
          <div className='select-refresh-container'>
            <div className='dropdown-refresh-button-container'>
            <SelectDropDown showSearch style={{width: 120}} defaultValue={"All"} options={AllOptions} />
            </div>
            <div className='refresh-icon-dropdown-container'>
            {/* <ButtonComponent name="Refresh" leftIcon={<Icon as={MdRefresh} />} /> */}
            <div className='refreshicon-dropdown'>
            <img className='refresh-icon' src={refreshIcon} alt='refreshIcon' />
            <SelectDropDown showSearch style={{width: 180}}  defaultValue={"Auto-refresh disabled"} options={refreshOptions} />
            </div>
            </div>
        </div>
        <div className="filters-container">
        <Input placeholder={"Request ID"} value={requestId} onChange={onRequestIdChange}/>  
        <div style={{width: "15%"}}>
        <CustomDropDown allowClear placeholder={"Method"} value={method} onChange={onMethodChange} options={MethodList} />
        </div>
        <SelectDropDown allowClear showSearch style={{width: 180}}  placeholder={"Status"} value={statusCode} onChange={onStatusChange} options={StatusList} />
        {/* <SelectDropDown showSearch style={{width: 180}}  placeholder={"Method"} value={method} onChange={onMethodChange} options={MethodList} /> */}
        <Input placeholder={"Path"} value={path} onChange={onPathChange}/>  
        {/* <SelectDropDown style={{width: 180}}  placeholder={"Response Times"} options={refreshOptions} /> */}
        <SelectDropDown allowClear showSearch style={{width: 180}}  placeholder={"Application"} value={application} onChange={onApplicationChange} options={applicationListData} />
        <Input onChange={onEndpointChange} value={endpoint} placeholder={"Endpoint"}/>
      </div>
        <div className='time-range-picker-container'>
        <TimeRangePicker />
        <RangePickerFromTo onChange={onRangePickerChange} />
        <Button  
          onClick={onSearchClick}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="10%"
          h="43px"
          mt="1px">Search
        </Button>
        </div>
      </SimpleGrid>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1}}
        spacing={{ base: "20px", xl: "20px" }}>
        <DevelopmentTable columns={tableColumns} dataSource={tableData} 
        onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  onRowClick(record, rowIndex);
                },
              };
            }} />
      </SimpleGrid>
    </Box>
  );
}
