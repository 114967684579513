// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li.ant-menu-item.ant-menu-item-only-child {
    padding-left: 100px;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/AddServices/components/NavigationMenu.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB","sourcesContent":["li.ant-menu-item.ant-menu-item-only-child {\n    padding-left: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
