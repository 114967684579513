import { Box, Button, Flex, FormControl, FormLabel, Input, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SelectDropDown from "../Analytics/Components/SelectDropDown";
import DevelopmentTable from "../dataTables/components/DevelopmentTable";
import { useSelector, useDispatch } from "react-redux";
import { AllServices, ServiceAplication, Services } from "../../../Redux/actions/Services/Services";
import { deployApi } from "Redux/actions/ManageApi";
import { useHistory } from "react-router-dom";
import { getServiceList } from "Redux/actions/ManageApi";
import { getApplications } from "Redux/actions/Application";
import { EditOutlined } from "@ant-design/icons";
import { editApiToggle } from "Redux/actions/ManageApi";

export default function Settings() {
  const history = useHistory();
  const dispatch = useDispatch()
  // useEffect(() => {
  //  dispatch(ServiceList())
  // },[])

  useEffect(() => {
    // dispatch(AllServices());
    dispatch(getApplications());
    dispatch(ServiceAplication())
  }, [dispatch]);
  const serviceListData = useSelector((state) => state?.Service?.serviceList)
  const servicesData = useSelector((state) => state?.Service?.services?.result)
  console.log("servicesData",servicesData);
  console.log("serviceListData",serviceListData);
  const applicationList = useSelector((state) => state?.Application?.applications)
  console.log("applicationList",applicationList);
  const serviceListForDropDown = useSelector((state) => state?.ManageApi?.serviceList?.result)
  const serviceListForCreateApi = useSelector((state) => state?.Service?.allServices?.result?.content)
  console.log("serviceListForCreateApi",serviceListForCreateApi?.[0]);
  console.log("serviceListForDropDown",serviceListForDropDown);
  const AppServiceData = useSelector((state) => state?.Service?.serviceApplication?.result?.content);
  const onCreateApi = () => {
    history.push("/admin/create-api")
  };
  const serviceIdFromStorage = localStorage.getItem("serviceId")
  console.log("serviceIdFromStorage",typeof serviceIdFromStorage,serviceIdFromStorage);
  const [applicationListData,setApplicationListData] = useState([])
  const [ServiceListData,setServiceListData] = useState([])
  console.log("applicationListData",applicationListData);
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [applicationName,setApplicationName] = useState(null)
  const [serviceName, setServiceName] = useState(null)

  // useEffect(() => {
  //     dispatch(Services(19))
  // },[dispatch])

  useEffect(() => {
    if (serviceIdFromStorage && serviceIdFromStorage !== null) {
      localStorage.setItem("serviceId",serviceIdFromStorage) 
    }
    else if (AppServiceData && AppServiceData?.length > 0) {
      const defaultKeys = AppServiceData?.[0]?.services?.[0]?.id
      localStorage.setItem("serviceId",defaultKeys) 
    }
  },[AppServiceData])

  useEffect(() => {
    if (serviceIdFromStorage) {
      dispatch(Services(serviceIdFromStorage))
    }
  },[serviceIdFromStorage])

  useEffect(() => {
    if (applicationList && applicationList.length > 0) {
      const mappedApplications = applicationList?.map((item) => {
        console.log("applicationList",applicationList);
        return {
          label: item.name,
          value: item.name,
          name : item.id,
        }
      })
      setApplicationListData(mappedApplications)
      console.log("mappedApplications",mappedApplications);
    }
  },[applicationList])
  useEffect(() => {
    if (serviceListForDropDown && serviceListForDropDown?.length > 0) {
      const mappedServiceList = serviceListForDropDown?.map((item) => {
        console.log("logged inside");
        return {
          label: item?.serviceName,
          value: item?.serviceName,
          name: item?.id
        }
      })
      setServiceListData(mappedServiceList)
    }
    else {
      setServiceListData(null)
    }
  },[serviceListForDropDown])

  useEffect(() => {
  if(serviceListData && serviceListData.length){
    const mappedServices = serviceListData?.map(item => {
      return {
          value: item.id,
          label: item.serviceName,
      };
  });
  // setServiceValue(mappedServices);
  console.log("mappedServices",mappedServices[0])
  }
  }, [serviceListData])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' for an instant scroll or 'smooth' for a smooth scroll animation.
    });
  }, []);
  const onDeployClicked = (routeId) => {
    dispatch(deployApi(routeId,serviceIdFromStorage))
    console.log("clicked")
  }
  const columns = [
    {
      title: "Route Name",
      dataIndex: "name",
      key: "name",
      width: "10%"
    },
    {
      title: "Method",
      dataIndex: "method",
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
    },
    {
      title: "UpdatedBy",
      dataIndex: "updatedBy",
    },
    {
      title: "Path",
      dataIndex: "path",
    },
    {
      title: "Proxy Path",
      dataIndex: "proxyPath",
    },
    {
      title: "Deployment Type",
      dataIndex: "deploymentType",
    },
    {
      title: "Action",
      dataIndex: "isdeployed",
    },
    {
      title: <p></p>,
      dataIndex: "editApi",
    },
  ];

  const onEditClick = () => {
    history.push("/admin/update-api")
  }

  const editApiResponse = useSelector((state) => state?.ManageApi?.editApi?.statusCode)
  console.log("editApiResponse",editApiResponse);

  const data = [];
  servicesData?.content?.map((item, index) => {
      return data.push({
        key: index,
        name: item?.routeName,
        updatedAt: item?.updatedAt,
        proxyPath: item?.proxyPath,
        updatedBy: item?.updatedBy,
        path: item?.path,
        routeId: item?.routeId,
        deploymentType: item?.deploymentType,
        editApi: <EditOutlined onClick={onEditClick} className='edit-icon' />,
        isdeployed: item?.isDeployed === true ? "Deployed" : <Button
        onClick={(e) => (onDeployClicked(item?.routeId,index))}
      >
        Deploy
      </Button>,
        method: (
          <p
            className={
              item?.method === "POST"
                ? "method-post"
                : item?.method === "post"
                ? "method-post"
                : item?.method === "GET"
                ? "method-get"
                : item?.method === "DELETE"
                ? "method-delete"
                : item?.method === "PATCH"
                ? "method-put"
                : ""
            }
          >
            {item?.method}
          </p>
        ),
      });
    });
  
  console.log("serviceName",serviceName);
const onApplicationChange = (value,id) => {
  dispatch(getServiceList(id?.name))
  setApplicationName(value)
  setServiceName(null)
}

const onServiceChange = (value,id) => {
  console.log("value,id",value,id);
  dispatch(Services(id?.name))
  setServiceName(value)
  localStorage.setItem("serviceId",id?.name)
}

const onRowClick = (record, rowIndex) => {
  const keyValue = record?.key
  console.log("record, rowIndex",record, servicesData,rowIndex);
  dispatch(editApiToggle(servicesData?.content[keyValue]))
}

const tooltipText = (
  <div className="tooltip-conatiner">
    <p>
      It allows you to assign priorities to different gateways for optimized
      routing. Enable this feature to enhance network efficiency and
      reliability.
    </p>
  </div>
);

  return (
    <Box mt={{ base: "300px", md: "45px", xl: "45px" }}>
      <div style={{display: "flex"}}>
        <div style={{display: "flex", width: "32%",justifyContent: "space-between"}}>
        <SelectDropDown showSearch style={{width: 180,marginTop:"30px"}} allowClear value={applicationName} onChange={onApplicationChange} placeholder={"Applications"} options={applicationListData} />
        <SelectDropDown showSearch style={{width: 180,marginTop:"30px"}} allowClear value={serviceName} onChange={onServiceChange} placeholder={"Services"} options={ServiceListData} />
        </div>
        <div style={{width: "67%"}}>
        <FormControl display="flex" justifyContent="flex-end" marginTop="30px">
          <Button
            onClick={onCreateApi}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="25%"
            h="50"
            mb="24px"
          >
            Create API
          </Button>
        </FormControl>
        </div>
        </div>
      <SimpleGrid columns={{ sm: 1, md: 1 }}>
      {/* <Table columns={columnss} dataSource={datas} />; */}
      <DevelopmentTable onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  onRowClick(record, rowIndex);
                },
              };
            }} columns={columns} dataSource={data}  />
      </SimpleGrid>
     
    </Box>
  );
}
