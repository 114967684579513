// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logs-main-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.logs-heads {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    margin-right: 30px;
}   
.logs-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.log-single-column {
    margin: 5px;
    font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/dataTables/components/LogDetails.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,2BAA2B;AAC/B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".logs-main-container {\n    width: 100%;\n    display: flex;\n    justify-content: flex-start;\n}\n.logs-heads {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    font-weight: 600;\n    margin-right: 30px;\n}   \n.logs-data {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n.log-single-column {\n    margin: 5px;\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
