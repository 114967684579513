/* import {createStore, applyMiddleware} from 'redux'
import logger from 'redux-logger'
import reduxThunk from 'redux-thunk' 
import rootReducer from './rootReducer'

const middlewares = [reduxThunk]
middlewares.push(logger)
 if(process.env.NODE_ENV === "development") {
    middlewares.push(logger)
 }

const store= createStore(rootReducer, applyMiddleware(...middlewares))

export default store */

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'; // Import composeWithDevTools
import logger from 'redux-logger';
import reduxThunk from 'redux-thunk'; 
import rootReducer from './rootReducer';

const middlewares = [reduxThunk];

// Add logger middleware only in development environment
if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}

const store = createStore(
    rootReducer,
    applyMiddleware(...middlewares)
  //  composeWithDevTools(applyMiddleware(...middlewares)) // Use composeWithDevTools
);

export default store;
