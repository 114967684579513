// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-loader-containers{
    /* position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:rbga(255,255,255,.3);
    height: 400px;
    /* margin-left: 230px; */
    /* backdrop-filter: blur(10px); */
}`, "",{"version":3,"sources":["webpack://./src/views/admin/Analytics/Components/Loader/Loader.css"],"names":[],"mappings":"AAAA;IACI;;;;;mBAKe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,qCAAqC;IACrC,aAAa;IACb,wBAAwB;IACxB,iCAAiC;AACrC","sourcesContent":[".dashboard-loader-containers{\n    /* position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 900; */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color:rbga(255,255,255,.3);\n    height: 400px;\n    /* margin-left: 230px; */\n    /* backdrop-filter: blur(10px); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
