import React, { useEffect, useState } from "react";
import "./TimePicker.css";
import { useDispatch } from "react-redux";
import { getDataLogsByFilter } from "Redux/actions/DataLogs";
import { timePickerData } from "Redux/actions/DataLogs";
const timeRanges = [
  { label: "Last 5m", value: 5, name: "MINUTES", val: 5   },
  { label: "30m", value: 30, name: "MINUTES", val: 120 },
  { label: "1h", value: 1, name: "HOURS",val: 180 },
  { label: "3h", value: 3, name: "HOURS",val: 360 },
  { label: "6h", value: 6, name: "HOURS",val: 400 },
  { label: "12h", value: 12, name: "HOURS",val: 720 },
  { label: "1d", value: 1, name: "DAYS",val: 1440 },
  { label: "3d", value: 3, name: "DAYS",val: 4320 },
  { label: "7d", value: 7, name: "DAYS",val: 10080 },
  { label: "14d", value: 14, name: "DAYS",val: 20160 },
  { label: "16d", value: 16, name: "DAYS",val: 20162 },
  { label: "30d", value: 30, name: "DAYS",val: 43200 },
  { label: "90d", value: 90, name: "DAYS",val: 129600 },
];

const TimeRangePicker = () => {
  const dispatch = useDispatch();
  
  const [selectedTimeRange, setSelectedTimeRange] = useState(5);

  const handleTimeRangeClick = (value, name,val) => {
    dispatch(timePickerData(value,name))
    setSelectedTimeRange(val);
    console.log("setSelectedTimeRange", value, name);
    const apiData = {
      "unit": value,
      "unitMeasure": name
  }
    dispatch(getDataLogsByFilter(apiData))
  };

  useEffect(() => {
    dispatch(timePickerData(5,"MINUTES"))
    const apiData = {
      "unit": 5,
      "unitMeasure": "MINUTES",
    }
    dispatch(getDataLogsByFilter(apiData))
  },[dispatch])

  return (
    <div className="TimeRangePicker">
      <div>
        {timeRanges.map((range) => (
          <button
            key={range.val}
            className={selectedTimeRange === range.val ? "selected" : ""}
            onClick={() => handleTimeRangeClick(range.value, range.name,range.val)}
          >
            {range.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TimeRangePicker;
