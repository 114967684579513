import * as types from "../actionTypes";

const initialState = {
  applications: {},
  createApp: {},
  editApp: {}
};

export const ApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_APPLICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        applications: action.payload,
        loading: false,
      };
    case types.GET_APPLICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.EDIT_APPLICATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_APPLICATION_SUCCESS:
      return {
        ...state,
        editApp: action.payload,
        loading: false,
      };
    case types.EDIT_APPLICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.CREATE_APPLICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        createApp: action.payload,
        loading: false,
      };
    case types.CREATE_APPLICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
