import * as types from "../actionTypes";

const initialState = {
  serviceLogs: {},
};

export const ServiceLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SERVICE_LOGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SERVICE_LOGS_SUCCESS:
      return {
        ...state,
        serviceLogs: action.payload,
        loading: false,
      };
    case types.SERVICE_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
