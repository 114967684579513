import * as types from "../../actionTypes";

const initialState = {
  routeproxy: false,
  cookiesEnabled: false,
  headersEnabled: false,
  serviceList: {},
  services: {},
  allServices: {},
  createServices: {},
  serviceApplication: {},
  serviceData: {},
  editService: {},
  editServiceToggleButton: false,
  selectedId: null,
};

export const ServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ROUTE_PROXY_ENABLED:
      return {
        ...state,
        routeproxy: action.payload,
        loading: false,
      };
    case types.COOKIES_ENABLED:
      return {
        ...state,
        cookiesEnabled: action.payload,
        loading: false,
      };
    case types.HEADERS_ENABLED:
      return {
        ...state,
        headersEnabled: action.payload,
        loading: false,
      };
    case types.SERVICE_DATA:
      return {
        ...state,
        serviceData: action.payload,
        loading: false,
      };
    case types.EDIT_SERVICE_TOGGLE:
      return {
        ...state,
        editServiceToggleButton: action.payload,
        loading: false,
      };
    case types.SELECTED_SERVICE_ID:
      return {
        ...state,
        selectedId: action.payload,
        loading: false,
      };
    case types.GET_SERVICE_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        serviceList: action.payload,
        loading: false,
      };
    case types.GET_SERVICE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.EDIT_SERVICE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_SERVICE_SUCCESS:
      return {
        ...state,
        editService: action.payload,
        loading: false,
      };
    case types.EDIT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.GET_SERVICE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SERVICE_SUCCESS:
      return {
        ...state,
        services: action.payload,
        loading: false,
      };
    case types.GET_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.GET_ALL_SERVICE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_SERVICE_SUCCESS:
      return {
        ...state,
        allServices: action.payload,
        loading: false,
      };
    case types.GET_ALL_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.CREATE_SERVICE_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        createServices: action.payload,
        loading: false,
      };
    case types.CREATE_SERVICE_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.SERVICE_APPLICATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SERVICE_APPLICATION_SUCCESS:
      return {
        ...state,
        serviceApplication: action.payload,
        loading: false,
      };
    case types.SERVICE_APPLICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
