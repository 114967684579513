import { Box, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "./Analytics.css";
import DetailsCard from "./Components/DetailsCard";
import LineChart from "./Components/LineChartWithLoader";
import PieCard from "./Components/PieCard";
import StatsCard from "./Components/StatsCard";
import {
  applicationTableColumns,
  applicationTableData,
  hitsTableColumns,
  hitsTableData,
  pathTableColumns,
  pathTableData,
  slowApplicationTableColumns,
  slowApplicationTableData,
} from "./Components/TableData";
import ScatterChart from "./Components/ScatteredChartWithLoader";
import { useDispatch, useSelector } from "react-redux";
import { dashboardMetrics } from "Redux/actions/Dashboard";
import { Loader } from "components/Loader/Loader";
import SelectDropDown from "./Components/SelectDropDown";
import { getServiceList } from "Redux/actions/ManageApi";
import { Services } from "Redux/actions/Services/Services";
import { getApplications } from "Redux/actions/Application";
import { ServiceAplication } from "Redux/actions/Services/Services";
import { dashboardAppDetailsMetrics } from "Redux/actions/Dashboard";

const Analytics = () => {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loaderToggle, setLoaderToggle] = useState(true);
  const [applicationName, setApplicationName] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [applicationListData, setApplicationListData] = useState([]);
  const [ServiceListData, setServiceListData] = useState([]);
  const [apiList, setApiList] = useState([]);
  const [selectedApi, setSelectedApi] = useState(null);
  const [selectedApiName, setSelectedApiName] = useState(null);

  const metricsData = useSelector((state) => state?.Dashboard?.metrics);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const applicationList = useSelector(
    (state) => state?.Application?.applications
  );
  console.log("applicationList", applicationList);
  const serviceListForDropDown = useSelector(
    (state) => state?.ManageApi?.serviceList?.result
  );
  const serviceListData = useSelector(
    (state) => state?.Service?.serviceApplication?.result?.content
  );
  console.log(
    "serviceListData",
    serviceListForDropDown,
    serviceListData,
    applicationList
  );
  const serviceDetails = useSelector(
    (state) => state?.Service?.services?.result?.content
  );
  console.log("serviceDetails", serviceDetails);

  useEffect(() => {
    dispatch(dashboardMetrics({}));
    dispatch(getApplications());
    dispatch(ServiceAplication());
    dispatch(dashboardAppDetailsMetrics());
  }, []);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' for an instant scroll or 'smooth' for a smooth scroll animation.
    });
    localStorage.removeItem("serviceId");
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoaderToggle(false);
    }, 1000);
  }, [metricsData]);

  useEffect(() => {
    if (applicationList && applicationList.length > 0) {
      const mappedApplications = applicationList?.map((item) => {
        return {
          label: item.name,
          value: item.name,
          name: item.id,
        };
      });
      setApplicationListData(mappedApplications);
    }
  }, [applicationList]);

  useEffect(() => {
    if (serviceDetails && serviceDetails?.length > 0) {
      const mappedServiceDetails = serviceDetails?.map((item) => {
        return {
          label: item?.routeName,
          value: item?.path,
          name: item?.proxyPath,
        };
      });
      setApiList(mappedServiceDetails);
    } else {
      setApiList(null);
    }
  }, [serviceDetails]);

  useEffect(() => {
    if (serviceListForDropDown && serviceListForDropDown?.length > 0) {
      const mappedServiceList = serviceListForDropDown?.map((item) => {
        return {
          label: item?.serviceName,
          value: item?.serviceName,
          name: item?.id,
        };
      });
      setServiceListData(mappedServiceList);
    } else {
      setServiceListData(null);
    }
  }, [serviceListForDropDown]);
  console.log("applicationwork", applicationName, serviceName, selectedApi);

  const onApplicationChange = (value, id) => {
    dispatch(getServiceList(id?.name));
    setApplicationName(value);
    setServiceName(null);
    setSelectedApi(null);
    setSelectedApiName(null);
    setApiList(null);
    setServiceListData(null);
  };

  const onServiceChange = (value, id) => {
    dispatch(Services(id?.name));
    setServiceName(value);
    setSelectedApi(null);
    setSelectedApiName(null);
    setApiList(null);
  };

  const onApiChange = (value, id) => {
    setSelectedApiName(id?.label);
    if (id?.name === null) {
      setSelectedApi(id?.value);
    } else setSelectedApi(id?.name);

    const apiData = {
      unit: 10,
      unitMeasure: "MINUTES",
      filters: {
        app: applicationName,
        serviceName: serviceName,
        path: id?.name === null ? id?.value : id?.name,
      },
    };
    dispatch(dashboardMetrics(apiData));
  };

  return (
    <Box mt={{ base: "300px", md: "45px", xl: "45px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px">
        <br />
        <Box>
          <StatsCard />
        </Box>
      </SimpleGrid>
      <br />
      <Box
        backgroundColor={"white"}
        p={"20px"}
        height={"170px"}
        borderRadius={"10px"}
      >
        <div className="application-name-select-container">
          <p>
            <b>Application:</b> {applicationName}
          </p>
          <SelectDropDown
            showSearch
            style={{ width: 180 }}
            allowClear
            value={applicationName}
            onChange={onApplicationChange}
            placeholder={"Applications"}
            options={applicationListData}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "25px",
            width: "32%",
            justifyContent: "space-between",
          }}
        >
          <SelectDropDown
            showSearch
            style={{ width: 180 }}
            allowClear
            value={serviceName}
            onChange={onServiceChange}
            placeholder={"Services"}
            options={ServiceListData}
          />
          <SelectDropDown
            showSearch
            style={{ width: 180 }}
            allowClear
            value={selectedApiName}
            onChange={onApiChange}
            placeholder={"API"}
            options={apiList}
          />
        </div>
      </Box>
      <br />
      <LineChart />
      <br />
      <ScatterChart />
      <br />
      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px">
        <Box width="100%" height="440px">
          <PieCard height="100%" />
        </Box>
        <DetailsCard
          cardName="Top Applications"
          shortCardName="Ordered by application calls"
          rowSelection={rowSelection}
          columns={applicationTableColumns}
          dataSource={applicationTableData}
        />
        <DetailsCard
          cardName="Top Paths"
          shortCardName="Hits repartition by path"
          rowSelection={rowSelection}
          columns={pathTableColumns}
          dataSource={pathTableData}
        />
        <DetailsCard
          cardName="Top slow Applications"
          shortCardName="Ordered by Application's average response time"
          columns={slowApplicationTableColumns}
          dataSource={slowApplicationTableData}
        />
        <DetailsCard
          cardName="Hit by Host"
          shortCardName="Hits repartition by Host HTTP Header"
          rowSelection={rowSelection}
          columns={hitsTableColumns}
          dataSource={hitsTableData}
        />
        <DetailsCard
          cardName="Top mapped paths"
          shortCardName="Hits repartition by mapped path"
          columns={[]}
          dataSource={[]}
        />
      </SimpleGrid>
      <br />
    </Box>
  );
};

export default Analytics;
