import React from "react";
import { Input } from "@chakra-ui/react";

const InputForLogs = (props) => {
  return (
    <Input
      isRequired={true}
      variant="auth"
      fontSize="sm"
      ms={{ base: "0px", md: "0px" }}
      type={props.type}
      placeholder={props.placeholder}
      mb="24px"
      fontWeight="500"
      size="lg"
      onChange={props.onChange}
      width={"180px"}
    />
  );
};

export default InputForLogs;
