import * as types from "../actionTypes";

const initialState = {
  deployApi: {},
  createAPI: {},
  serviceList: [],
  editApi: {},
  editApiData: {},
  testApiResp: {},
};

export const ManageApiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_API_MODAL_TOGGLE:
      return {
        ...state,
        loading: false,
        editApiData: action.payload
      };
    case types.DEPLOY_API_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.DEPLOY_API_SUCCESS:
      return {
        ...state,
        deployApi: action.payload,
        loading: false,
      };
    case types.DEPLOY_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case types.CREATE_MANAGE_API_DATA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_MANAGE_API_DATA_SUCCESS:
      return {
        ...state,
        createAPI: action.payload,
        loading: false,
      };
    case types.CREATE_MANAGE_API_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case types.GET_SERVICES_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SERVICES_LIST_SUCCESS:
      return {
        ...state,
        serviceList: action.payload,
        loading: false,
      };
    case types.GET_SERVICES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.EDIT_API_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_API_SUCCESS:
      return {
        ...state,
        editApi: action.payload,
        loading: false,
      };
    case types.EDIT_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.TEST_API_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.TEST_API_SUCCESS:
      return {
        ...state,
        testApiResp: action.payload,
        loading: false,
      };
    case types.TEST_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
