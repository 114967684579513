import * as types from "../actionTypes";

const initialState = {
  metrics: {},
  appDetailsData: {},
};

export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_METRICS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.DASHBOARD_METRICS_SUCCESS:
      return {
        ...state,
        metrics: action.payload,
        loading: false,
      };
    case types.DASHBOARD_METRICS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.DASHBOARD_APP_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.DASHBOARD_APP_DETAILS_SUCCESS:
      return {
        ...state,
        appDetailsData: action.payload,
        loading: false,
      };
    case types.DASHBOARD_APP_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
