import axios from "axios";
import * as types from "../../actionTypes";
import { toast } from "components/Toast/Toast";

export const isRouteProxyEnabled = (payload) => ({
  type: types.ROUTE_PROXY_ENABLED,
  payload,
});
export const isCookiesEnabled = (payload) => ({
  type: types.COOKIES_ENABLED,
  payload,
});
export const isHeadersEnabled = (payload) => ({
  type: types.HEADERS_ENABLED,
  payload,
});
export const ServiceData = (payload) => ({
  type: types.SERVICE_DATA,
  payload,
});
export const EditServiceToggle = (payload) => ({
  type: types.EDIT_SERVICE_TOGGLE,
  payload,
});
export const selectedServiceId = (payload) => ({
  type: types.SELECTED_SERVICE_ID,
  payload,
});

const getServiceListLoading = () => ({
  type: types.GET_SERVICE_LIST_LOADING,
});
const getServiceListSuccess = (data) => ({
  type: types.GET_SERVICE_LIST_SUCCESS,
  payload: data,
});
const getServiceListFail = (error) => ({
  type: types.GET_SERVICE_LIST_FAIL,
  payload: error,
});
const getServiceLoading = () => ({
  type: types.GET_SERVICE_LOADING,
});
const getServiceSuccess = (data) => ({
  type: types.GET_SERVICE_SUCCESS,
  payload: data,
});
const getServiceFail = (error) => ({
  type: types.GET_SERVICE_FAIL,
  payload: error,
});
const getAllServiceLoading = () => ({
  type: types.GET_ALL_SERVICE_LOADING,
});
const getAllServiceSuccess = (data) => ({
  type: types.GET_ALL_SERVICE_SUCCESS,
  payload: data,
});
const getAllServiceFail = (error) => ({
  type: types.GET_ALL_SERVICE_FAIL,
  payload: error,
});

const getCreateServiceLoading = () => ({
  type: types.CREATE_SERVICE_DATA_LOADING,
});
const getCreateServicesSuccess = (data) => ({
  type: types.CREATE_SERVICE_DATA_SUCCESS,
  payload: data,
});
const getCreateServicesFail = (error) => ({
  type: types.CREATE_SERVICE_DATA_FAIL,
  payload: error,
});
const editServiceLoading = () => ({
  type: types.EDIT_SERVICE_LOADING,
});
const editServicesSuccess = (data) => ({
  type: types.EDIT_SERVICE_SUCCESS,
  payload: data,
});
const editServicesFail = (error) => ({
  type: types.EDIT_SERVICE_FAIL,
  payload: error,
});
const ServiceApplicationLoading = () => ({
  type: types.SERVICE_APPLICATION_LOADING,
});
const ServiceApplicationSuccess = (data) => ({
  type: types.SERVICE_APPLICATION_SUCCESS,
  payload: data,
});
const ServiceApplicationFail = (error) => ({
  type: types.SERVICE_APPLICATION_FAIL,
  payload: error,
});

let url = process.env.REACT_APP_BASE_URL_GET;
let urlPost = process.env.REACT_APP_BASE_URL_POST;

export const ServiceList = () => {
  return (dispatch) => {
    dispatch(getServiceListLoading());
    axios
      .get(`${url}qry/api/v1/service/list`)
      .then((resp) => {
        dispatch(getServiceListSuccess(resp.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getServiceListFail(error));
      });
  };
};
export const Services = (serviceId) => {
  return (dispatch) => {
    dispatch(getServiceLoading());
    axios
      .get(`${url}qry/api/v1/routes/${serviceId}?page=0&size=1000000000`)
      .then((resp) => {
        dispatch(getServiceSuccess(resp.data));
        if (resp?.data && resp.data?.result?.content?.length === 0) {
          toast.error("No Data Found")
        }
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getServiceFail(error));
      });
  };
};
export const AllServices = () => {
  return (dispatch) => {
    dispatch(getAllServiceLoading());
    axios
      .get(`${url}qry/api/v1/services?page=0&size=100000000`)
      .then((resp) => {
        dispatch(getAllServiceSuccess(resp.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getAllServiceFail(error));
      });
  };
};

export const CreateServices = (data, id) => {
  return (dispatch) => {
    dispatch(getCreateServiceLoading());
    axios
      .post(`${urlPost}cmd/api/v1/service/${id}`, data)
      .then((resp) => {
        console.log("hello 2 api success", resp);
        dispatch(getCreateServicesSuccess(resp.data));
        toast.success("Service Added Successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(getCreateServicesFail(error));
        toast.error(error?.response?.data?.detail)
      });
  };
};

export const EditServices = (id, data) => {
  return (dispatch) => {
    dispatch(editServiceLoading());
    axios
      .patch(`${urlPost}cmd/api/v1/service/${id}`, data)
      .then((resp) => {
        console.log("hello 2 api success", resp);
        dispatch(editServicesSuccess(resp.data));
        dispatch(ServiceAplication())
        toast.success("Service Added Successfully");
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(editServicesFail(error));
      });
  };
};

export const ServiceAplication = () => {
  return (dispatch) => {
    dispatch(ServiceApplicationLoading());
    axios
      .get(`${url}qry/api/v1/apps?page=0&size=10000000`)
      .then((resp) => {
        dispatch(ServiceApplicationSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(ServiceApplicationFail(error));
      });
  };
};
