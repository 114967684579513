import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import { Checkbox, Input, Switch } from "antd";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SelectDropDown from "views/admin/Analytics/Components/SelectDropDown";
import {
  createManageApi,
  getServiceList,
  testApiData,
} from "../../../../Redux/actions/ManageApi";
import { AllServices } from "Redux/actions/Services/Services";
import CustomDropDown from "./CustomDropDown";
import { getApplications } from "Redux/actions/Application";
import "./CreateApiModal.css";

import CustomToolTip from "components/Tooltip/CustomTooltip";
import { useHistory } from "react-router-dom";
import "./index.css";
import { selectedServiceId } from "Redux/actions/Services/Services";
export default function CreatApi() {
  const dispatch = useDispatch();
  const history = useHistory();
  const serviceListForCreateApi = useSelector(
    (state) => state?.Service?.allServices?.result?.content
  );
  console.log("serviceListForCreateApi", serviceListForCreateApi);
  const applicationList = useSelector(
    (state) => state?.Application?.applications
  );
  const serviceListForDropDown = useSelector(
    (state) => state?.ManageApi?.serviceList?.result
  );
  console.log("applicationList", applicationList);
  const createApplication = useSelector((state) => state?.ManageApi?.createAPI);
  console.log("createApplication", createApplication);
  console.log("serviceListForCreateApi", serviceListForCreateApi);

  const tooltipText = (
    <div className="tooltip-conatiner">
      <p>
        It allows you to assign priorities to different gateways for optimized
        routing. Enable this feature to enhance network efficiency and
        reliability.
      </p>
    </div>
  );

  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [isActive, setSelectedValue] = useState(true);
  const [isWeightEnabled, setOnWeightEnable] = useState(false);
  const [cookiesItems, setCookiesGridItems] = useState([
    { key: null, value: null },
  ]);
  const [hostRemoteItems, setHostRemoteItems] = useState([
    {
      hostRemoteCount: 1,
    },
  ]);
  let [host, setHostItems] = useState("");
  let [remoteAddress, setRemoteAddressItems] = useState("");
  const [headerItems, setHeaderGridItems] = useState([
    { key: null, value: null },
  ]);
  const [serviceID, setServiceID] = useState();
  const [name, setName] = useState(null);
  const [method, setMethod] = useState("GET");
  const [description, setDescription] = useState(null);
  const [weight, setWeightValue] = useState(null);
  const [proxyPath, setProxyPathValue] = useState(null);
  const [path, setPathValue] = useState(null);
  const [Cookies, setCookiesValue] = useState(null);
  const [headers, setHeaderValue] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [methodError, setMethodError] = useState(false);
  const [serviceIDError, setServiceIDError] = useState(false);
  const [applicationError, setApplicationError] = useState(false);
  const [servicesForCreate, setServicesForCreate] = useState([]);
  const [applicationListData, setApplicationListData] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [applicationName, setApplicationName] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [pathProxied, setProxyToggle] = useState(false);
  const [proxyPathError, setProxyPathError] = useState(false);
  const [pathError, setPathError] = useState(false);
  const [testPathData, setTestPathData] = useState([]);
  const [testApiToggle, setTestApiToggle] = useState(true);

  const statusList = [
    {
      label: "Active",
      value: true,
    },
    {
      label: "InActive",
      value: false,
    },
  ];

  useEffect(() => {
    dispatch(getApplications());
    dispatch(AllServices());
  }, [dispatch]);

  useEffect(() => {
    if (applicationList && applicationList.length > 0) {
      const mappedApplications = applicationList?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setApplicationListData(mappedApplications);
      console.log("mappedApplications", mappedApplications);
    }
  }, [applicationList]);

  useEffect(() => {
    if (serviceListForDropDown && serviceListForDropDown?.length > 0) {
      const mappedServicesForCreate = serviceListForDropDown?.map((item) => {
        return {
          label: item?.serviceName,
          value: item?.id,
        };
      });
      setServicesForCreate(mappedServicesForCreate);
    } else {
      setServicesForCreate(null);
    }
  }, [serviceListForDropDown]);

  useEffect(() => {
    if (createApplication && Object.keys(createApplication).length > 0) {
      setName(null);
      setMethod(null);
      setDescription(null);
      setWeightValue(null);
      setPathValue(null);
      setProxyPathValue(null);
      setProxyToggle(false);
      setSelectedValue(true);
      setProxyPathError(false);
      setCookiesValue([]);
      setHeaderValue(null);
      setHostItems("");
      setRemoteAddressItems("");
      setApplicationName("");
      setServiceName("");
      setSelectedApplication(null);
      setCookiesGridItems([{ key: null, value: null }]);
      setHeaderGridItems([{ key: null, value: null }]);
      setHostRemoteItems([
        {
          hostRemoteCount: 1,
        },
      ]);
    }
  }, [createApplication]);

  const handleCookiesAddItem = () => {
    if (cookiesItems.length < 3) {
      setCookiesGridItems([...cookiesItems, { key: "", value: "" }]);
    }
  };

  const handleCookiesDeleteItem = (index) => {
    const updatedGridItems = [...cookiesItems];
    updatedGridItems.splice(index, 1);
    setCookiesGridItems(updatedGridItems);
  };

  // const handleCookiesInputChange = (index, field, value) => {
  //   const updatedGridItems = [...cookiesItems];
  //   updatedGridItems[index][field] = value;
  //   setCookiesGridItems(updatedGridItems);
  // };

  const handleCookiesInputChange = (index, field, value) => {
    const updatedGridItems = [...cookiesItems];
    updatedGridItems[index][field] = value;

    const updatedCookies = {}; // Initialize an empty object

    updatedGridItems.forEach((item, index) => {
      updatedCookies[`key${index + 1}`] = item.key;
      updatedCookies[`value${index + 1}`] = item.value;
    });

    setCookiesGridItems(updatedGridItems);
    setCookiesValue(updatedCookies); // Assuming you have a state for 'cookies'
  };

  const handleHeaderAddItem = () => {
    if (headerItems.length < 3) {
      setHeaderGridItems([...headerItems, { key: "", value: "" }]);
    }
  };

  const handleHeaderDeleteItem = (index) => {
    const updatedGridItems = [...headerItems];
    updatedGridItems.splice(index, 1);
    setHeaderGridItems(updatedGridItems);
  };

  const handleHeaderInputChange = (index, field, value) => {
    const updatedGridItems = [...headerItems];
    updatedGridItems[index][field] = value;

    const updatedHeader = {}; // Initialize an empty object

    updatedGridItems.forEach((item, index) => {
      updatedHeader[`key${index + 1}`] = item.key;
      updatedHeader[`value${index + 1}`] = item.value;
    });

    setHeaderGridItems(updatedGridItems);
    setHeaderValue(updatedHeader);
  };

  const selectHandle = (value, label) => {
    console.log("hello11 selected", value, label);
    setSelectedValue(value);
  };
  const checkboxWeightHandler = (e) => {
    // console.log("checkedddddd", e.target.checked);
    if (e.target.checked === true) {
      setOnWeightEnable(true);
    } else setOnWeightEnable(false);
  };

  const onServiceChange = (value, id) => {
    console.log("value.id", value, id, typeof value);
    const filteredData = serviceListForCreateApi?.filter(
      (item) => item?.serviceName == id?.label
    );
    setTestPathData(filteredData);
    console.log("filteredData", filteredData);
    if (value === "" || value === null || value === undefined) {
      setServiceIDError(true);
    } else setServiceIDError(false);
    console.log("value, id:", value, id);
    setServiceID(value);
    setServiceName(id?.label);

    if (path !== null && value !== undefined) {
      setTestApiToggle(false);
    } else setTestApiToggle(true);
  };
  const nameHandler = (e) => {
    const inputValue = e.target.value;
    setName(inputValue);
    if (/^\s/.test(inputValue)) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  const methodHandler = (value) => {
    setMethod(value);
    setMethodError(false);
  };

  const descHandler = (e) => {
    setDescription(e.target.value);
    setNameError(false);
  };
  const weightHandler = (e) => {
    setWeightValue(e.target.value);
  };

  const proxyPathHandler = (e) => {
    setProxyPathValue(e.target.value);
    if (e.target.value === "") setProxyPathError(false);
    if (e.target.value.trim() === "") {
      setProxyPathValue(null);
    }
    if (!e.target.value.startsWith("/")) setProxyPathError(true);
    else setProxyPathError(false);
  };
  const pathHandler = (e) => {
    setPathValue(e.target.value);
    if (e.target.value === "") setPathError(false);
    if (e.target.value.trim() === "") {
      setPathValue(null);
    }
    if (!e.target.value.startsWith("/")) setPathError(true);
    else setPathError(false);

    if (e.target.value !== "" && serviceName !== undefined) {
      setTestApiToggle(false);
    } else setTestApiToggle(true);
  };

  const nameValidations = () => {
    if (name !== "" && name !== null && name !== undefined) {
      setNameError(false);
      return true;
    } else {
      setNameError(true);
      return false;
    }
  };

  const pathValidations = () => {
    if (path === "" || path === null || path === undefined) {
      setPathError(true);
      return false;
    } else {
      setPathError(false);
      return true;
    }
  };

  const proxyPathValidations = () => {
    if (proxyPath === "" || proxyPath === null || proxyPath === undefined) {
      setProxyPathError(true);
      return false;
    } else {
      setProxyPathError(false);
      return true;
    }
  };
  const serviceIDValidations = () => {
    if (serviceID === "" || serviceID === null || serviceID === undefined) {
      setServiceIDError(true);
      return false;
    } else {
      setServiceIDError(false);
      return true;
    }
  };

  const applicationValidation = () => {
    if (
      selectedApplication === "" ||
      selectedApplication === null ||
      selectedApplication === undefined
    ) {
      setApplicationError(true);
      return false;
    } else {
      setApplicationError(false);
      return true;
    }
  };

  const checkValidations = () => {
    if (
      (nameValidations() === true) &
      (pathValidations() === true) &
      (applicationValidation() === true) &
      (serviceIDValidations() === true)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [apiObject, setApiObject] = useState({ method, isActive });
  console.log("apiObject", apiObject);
  useEffect(() => {
    if (name === null || name === undefined || name === "") {
      const { name, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, name }));
    }
  }, [name]);
  useEffect(() => {
    if (method === null || method === undefined || method === "") {
      const { method, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, method }));
    }
  }, [method]);
  useEffect(() => {
    if (
      description === null ||
      description === undefined ||
      description === ""
    ) {
      const { description, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, description }));
    }
  }, [description]);
  useEffect(() => {
    if (weight === null || weight === undefined || weight === "") {
      const { weight, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, weight }));
    }
  }, [weight]);
  useEffect(() => {
    if (
      isWeightEnabled === null ||
      isWeightEnabled === undefined ||
      isWeightEnabled === ""
    ) {
      const { isWeightEnabled, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, isWeightEnabled }));
    }
  }, [isWeightEnabled]);
  useEffect(() => {
    if (path === null || path === undefined || path === "") {
      const { path, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, path }));
    }
  }, [path]);
  useEffect(() => {
    if (proxyPath === null || proxyPath === undefined || proxyPath === "") {
      const { proxyPath, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, proxyPath }));
    }
  }, [proxyPath]);
  useEffect(() => {
    if (
      pathProxied === null ||
      pathProxied === undefined ||
      pathProxied === ""
    ) {
      const { pathProxied, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, pathProxied }));
    }
  }, [pathProxied]);
  useEffect(() => {
    if (host === null || host === undefined || host === "") {
      const { host, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      host = host.toString();
      setApiObject((prevObject) => ({ ...prevObject, host }));
    }
  }, [host]);
  useEffect(() => {
    if (
      remoteAddress === null ||
      remoteAddress === undefined ||
      remoteAddress === ""
    ) {
      const { remoteAddress, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      remoteAddress = remoteAddress.toString();
      setApiObject((prevObject) => ({ ...prevObject, remoteAddress }));
    }
  }, [remoteAddress]);
  useEffect(() => {
    if (isActive === null || isActive === undefined || isActive === "") {
      const { isActive, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, isActive }));
    }
  }, [isActive]);
  useEffect(() => {
    if (Cookies === null || Cookies === undefined || Cookies === "") {
      const { Cookies, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, Cookies }));
    }
  }, [Cookies]);
  useEffect(() => {
    if (headers === null || headers === undefined || headers === "") {
      const { headers, ...rest } = apiObject;
      setApiObject(rest);
    } else {
      setApiObject((prevObject) => ({ ...prevObject, headers }));
    }
  }, [headers]);

  const submitHandler = (e) => {
    const value = checkValidations();
    const proxyCheck = proxyPathValidations();
    if (pathProxied === true) {
      if (value === true && proxyCheck === true) {
        dispatch(createManageApi(apiObject, serviceID));
      }
    } else if (value === true) {
      dispatch(createManageApi(apiObject, serviceID));
    }
  };

  const testApiHandler = () => {
    const uri = testPathData?.[0]?.uri;
    const contextPath = testPathData?.[0]?.contextPath;
    const url = `${uri}${
      contextPath !== null ? `${contextPath.replace(/^\//, "")}` : ""
    }${contextPath === null ? path.replace(/^\//, "") : path}`;
    dispatch(testApiData(method, url));
  };

  const onApplicationChange = (value, id) => {
    if (value === "" || value === null || value === undefined) {
      setApplicationError(true);
      setServiceID(null);
      setServiceName(null);
      setServiceIDError(true);
    } else {
      setApplicationError(false);
    }
    setSelectedApplication(value);
    dispatch(getServiceList(value));
    setApplicationName(id?.label);
    setServiceID(null);
    setServiceName(null);
  };

  const onApplicationSearch = (e) => {
    console.log("e");
  };

  const onProxyToggleChange = (value) => {
    setProxyToggle(value);
  };

  const handleHostAddressItems = () => {
    // Increment hostRemoteCount by 1
    setHostRemoteItems((prevState) => [
      { hostRemoteCount: prevState[0].hostRemoteCount + 1 },
    ]);
  };

  const hostAddressDeleteItem = (index) => {
    console.log("indexvalued", index);
    setHostRemoteItems((prevState) => [
      { hostRemoteCount: prevState[0].hostRemoteCount - 1 },
    ]);
    const updatedArray = [...host];
    updatedArray.splice(index, 1);
    setHostItems(updatedArray);
    const updatedArrays = [...remoteAddress];
    updatedArrays.splice(index, 1);
    setRemoteAddressItems(updatedArrays);
  };

  const handleHostInput = (index, value) => {
    const updatedArray = [...host];
    updatedArray[index] = value;
    setHostItems(updatedArray);
  };
  const handleAddressInput = (index, value) => {
    const updatedArray = [...remoteAddress];
    updatedArray[index] = value;
    setRemoteAddressItems(updatedArray);
  };

  const onBackHandle = () => {
    history.push("/admin/manage-api");
  };

  const [disableTest, setDisableTest] = useState(true);
  useEffect(() => {
    if (path !== null && serviceID !== null) {
      setDisableTest(false);
    } else setDisableTest(true);
  }, [pathError, serviceIDError]);

  return (
    <Box mt={{ base: "300px", md: "80px", xl: "80px" }}>
      <p className="back-button" onClick={onBackHandle}>
        &lt;Back
      </p>
      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "10px", xl: "10px" }}
      >
        <FormControl
          background={"white"}
          padding={"20px"}
          borderRadius={"20px"}
        >
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Name<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            placeholder="Inventory"
            fontWeight="500"
            maxLength={35}
            size="large"
            value={name}
            onChange={nameHandler}
          />
          {nameError ? (
            <p style={{ color: "red", marginBottom: "5px", fontSize: "11px" }}>
              ** Please enter Name
            </p>
          ) : (
            <p></p>
          )}
          <FormLabel
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            display="flex"
            mt="10px"
          >
            Method<Text color={brandStars}>*</Text>
          </FormLabel>
          {/* <Input width={"50%"} placeholder="Get" size="large" /> */}
          {/* <SelectDropDown
            style={{ width: "100%" }}
            onChange={methodHandler}
            value={method}
            placeholder={"Select Method"}
            options={methodOptions}
          /> */}

          <CustomDropDown
            onChange={methodHandler}
            value={method || "GET"}
            defaultValue={"GET"}
            placeholder={"Select Method"}
            dropDownValue={method}
          />
          {methodError ? (
            <p style={{ color: "red", marginBottom: "5px", fontSize: "11px" }}>
              ** Please choose method
            </p>
          ) : (
            <p></p>
          )}

          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            mt="20px"
          >
            Path<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            // disabled={!routeProxyToggle}
            placeholder="/demo/inv"
            fontWeight="500"
            size="large"
            type="text"
            onChange={pathHandler}
            value={path}
          />
          {pathError ? (
            <p style={{ color: "red", fontSize: "11px" }}>
              ** Please Enter Valid Path
            </p>
          ) : (
            <p></p>
          )}
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            mt="20px"
          >
            Application Name<Text color={brandStars}>*</Text>
          </FormLabel>

          <SelectDropDown
            style={{ width: "100%" }}
            allowClear
            onSearch={onApplicationSearch}
            onChange={onApplicationChange}
            value={applicationName}
            placeholder={"Applications"}
            options={applicationListData}
          />
          {applicationError ? (
            <p style={{ color: "red", marginTop: "20px", fontSize: "11px" }}>
              ** Please choose Application
            </p>
          ) : (
            <p></p>
          )}

          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            mt="20px"
          >
            Service Name<Text color={brandStars}>*</Text>
          </FormLabel>
          <SelectDropDown
            style={{ width: "100%" }}
            onChange={onServiceChange}
            allowClear
            placeholder={"Select Service"}
            value={serviceName}
            options={servicesForCreate}
          />
          {serviceIDError ? (
            <p style={{ color: "red", marginTop: "20px", fontSize: "11px" }}>
              ** Please choose Service
            </p>
          ) : (
            <p></p>
          )}

          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            mt="20px"
          >
            Description
          </FormLabel>
          <Input
            placeholder="Description"
            size="large"
            maxLength={254}
            onChange={descHandler}
            value={description}
          />
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            mt="10px"
          >
            Status<Text color={brandStars}>*</Text>
          </FormLabel>
          <SelectDropDown
            style={{ width: "100%" }}
            onChange={selectHandle}
            placeholder={"Select Status"}
            options={statusList}
            value={isActive}
          />
          <FormControl m={"20px 10px"}>
            <div className="checkbox-info-container">
              <Checkbox size="md" mt="8px" onChange={checkboxWeightHandler}>
                Enable Weight ?
              </Checkbox>
              <CustomToolTip text={tooltipText} />
            </div>
          </FormControl>
          {isWeightEnabled === true ? (
            <>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                mt="10px"
              >
                Weight<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                placeholder="1"
                size="large"
                onChange={weightHandler}
                value={weight}
              />
            </>
          ) : (
            ""
          )}
        </FormControl>
        <FormControl
          background={"white"}
          padding={"20px"}
          borderRadius={"20px"}
        >
          <Text
            color={textColor}
            fontSize="24px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
            marginBottom="10px"
          >
            Route Proxy
          </Text>

          <div className="proxy-toggle-customer">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              mt="10px"
            >
              Enable Proxy Path
            </FormLabel>
            <Switch checked={pathProxied} onChange={onProxyToggleChange} />
          </div>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            mt="10px"
          >
            Proxy Path
          </FormLabel>
          <Input
            disabled={!pathProxied}
            placeholder="Proxy/demo/inv"
            fontWeight="500"
            size="large"
            onChange={proxyPathHandler}
            value={proxyPath}
          />
          {pathProxied && proxyPathError ? (
            <p style={{ color: "red", fontSize: "11px" }}>
              ** Please Enter Valid Path
            </p>
          ) : (
            <p></p>
          )}

          <div className="host-remote-container">
            <FormControl
              display="flex"
              justifyContent="space-between"
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              {Array.from(
                { length: hostRemoteItems[0].hostRemoteCount },
                (_, index) => (
                  <Flex key={index} mb="16px">
                    <div key={index} style={{ marginRight: "20px" }}>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        {index === 0 ? "Host" : `Host ${index + 1}`}
                      </FormLabel>
                      <Input
                        placeholder={`Host ${index + 1}`}
                        fontWeight="500"
                        size="large"
                        value={host[index]}
                        onChange={(e) => handleHostInput(index, e.target.value)}
                      />
                    </div>
                    <div key={index} style={{ marginRight: "20px" }}>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                      >
                        {index === 0
                          ? "Remote Address"
                          : `Remote Address ${index + 1}`}
                      </FormLabel>
                      <Input
                        placeholder={`http::localhost${index + 1}`}
                        fontWeight="500"
                        size="large"
                        value={remoteAddress[index]}
                        onChange={(e) =>
                          handleAddressInput(index, e.target.value)
                        }
                      />
                    </div>
                    {hostRemoteItems[0].hostRemoteCount > 1 && (
                      <DeleteIcon
                        onClick={() => hostAddressDeleteItem(index)}
                        color="red.500"
                        style={{
                          cursor: "pointer", // Add pointer cursor
                        }}
                      />
                    )}
                  </Flex>
                )
              )}
            </FormControl>
            <div>
              {hostRemoteItems[0].hostRemoteCount < 10 && (
                <AddIcon
                  onClick={handleHostAddressItems}
                  style={{
                    cursor: "pointer", // Add pointer cursor
                  }}
                />
              )}
            </div>
          </div>

          <FormControl
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Text
              color={textColor}
              fontSize="24px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
              marginTop="10px"
              marginBottom="10px"
            >
              Cookies
            </Text>
            {cookiesItems.length < 3 && (
              <AddIcon
                onClick={handleCookiesAddItem}
                style={{
                  cursor: "pointer", // Add pointer cursor
                }}
              />
            )}
          </FormControl>
          <div>
            {cookiesItems.map((item, index) => (
              <Flex key={index} flexDirection="row" mb="16px">
                <div style={{ marginRight: "20px" }}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    {index === 0 ? "Key" : `Key ${index + 1}`}{" "}
                    {/* Conditionally render label */}
                  </FormLabel>
                  <Input
                    placeholder={`CookiesKey ${index + 1}`}
                    fontWeight="500"
                    size="large"
                    value={item.key}
                    onChange={(e) =>
                      handleCookiesInputChange(index, "key", e.target.value)
                    }
                  />
                </div>
                <div>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    {index === 0 ? "Value" : `Value ${index + 1}`}{" "}
                    {/* Conditionally render label */}
                  </FormLabel>
                  <Input
                    placeholder={`CookiesValue ${index + 1}`}
                    fontWeight="500"
                    size="large"
                    value={item.value}
                    onChange={(e) =>
                      handleCookiesInputChange(index, "value", e.target.value)
                    }
                  />
                </div>

                {cookiesItems.length > 1 && (
                  <DeleteIcon
                    onClick={() => handleCookiesDeleteItem(index)}
                    color="red.500"
                    style={{
                      cursor: "pointer", // Add pointer cursor
                    }}
                  />
                )}
              </Flex>
            ))}
          </div>
          <FormControl
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Text
              color={textColor}
              fontSize="24px"
              textAlign="start"
              fontWeight="700"
              lineHeight="100%"
              marginTop="10px"
              marginBottom="10px"
            >
              Header
            </Text>
            {headerItems.length < 3 && (
              <AddIcon
                onClick={handleHeaderAddItem}
                style={{
                  cursor: "pointer", // Add pointer cursor
                }}
              />
            )}
          </FormControl>
          <div>
            {headerItems.map((item, index) => (
              <Flex key={index} flexDirection="row" mb="16px">
                <div style={{ marginRight: "20px" }}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    {index === 0 ? "Key" : `Key ${index + 1}`}{" "}
                    {/* Conditionally render label */}
                  </FormLabel>
                  <Input
                    placeholder={`HeaderKey ${index + 1}`}
                    fontWeight="500"
                    size="large"
                    value={item.key}
                    onChange={(e) =>
                      handleHeaderInputChange(index, "key", e.target.value)
                    }
                  />
                </div>
                <div>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    {index === 0 ? "Value" : `Value ${index + 1}`}{" "}
                    {/* Conditionally render label */}
                  </FormLabel>
                  <Input
                    placeholder={`HeaderValue ${index + 1}`}
                    fontWeight="500"
                    size="large"
                    value={item.value}
                    onChange={(e) =>
                      handleHeaderInputChange(index, "value", e.target.value)
                    }
                  />
                </div>

                {headerItems.length > 1 && (
                  <DeleteIcon
                    onClick={() => handleHeaderDeleteItem(index)}
                    color="red.500"
                    style={{
                      cursor: "pointer", // Add pointer cursor
                    }}
                  />
                )}
              </Flex>
            ))}
          </div>
        </FormControl>
      </SimpleGrid>
      <FormControl
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Button
          disabled={testApiToggle}
          onClick={testApiHandler}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="20%"
          h="50"
          mt="24px"
        >
          Test Api
        </Button>
        <Button
          onClick={submitHandler}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="20%"
          h="50"
          mt="24px"
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  );
}
