import * as types from "../actionTypes";

const initialState = {
  loginData: {},
  tokenData: {},
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loginData: action.payload,
        loading: false,
      };
    case types.LOGIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.TOKEN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.TOKEN_SUCCESS:
      return {
        ...state,
        tokenData: action.payload,
        loading: false,
      };
    case types.TOKEN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
