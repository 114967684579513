import React from 'react'
import './Loader.css'
import { FadeLoader } from 'react-spinners'

export const Loader = (props) => {
  return (
    <div className='loader-container'>
        <FadeLoader  
        color={'#0C3D5E'}
        cssOverride={{ 
        borderColor: "#0C3D5E"}}
        loading={props.states}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"/>
    </div>
  )
}
