import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdDns,
  MdAnalytics,
} from "react-icons/md";
import NFTMarketplace from "views/admin/AddServices";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import ManageApi from "views/admin/manageApi";
import SignIn from "views/auth/signIn";
import Analytics from "views/admin/Analytics/Analytics";
import Logs from "../src/views/admin/dataTables/Logs/Logs";
import CreateApiModal from "../src/views/admin/manageApi/components/createApiModal";
import AddService from "../src/views/admin/AddServices/components/addServiceModal";
import AddApplication from "views/admin/AddApplication";
import AddApplicationDetails from "views/admin/AddApplication/Components/AddApplicationDetails";
import ServiceLogs from "views/admin/Servicelogs";
import UpdateApi from "views/admin/manageApi/components/UpdateApi";



const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" mt={"8px"} />
    ),
    component: Analytics,
  },
  {
    name: "Applications",
    layout: "/admin",
    path: "/add-application",
    icon: (
      <Icon as={MdDns} width="20px" height="20px" color="inherit" mt={"8px"} />
    ),
    component: AddApplication,
    secondary: true,
  },
  {
    name: "Services",
    layout: "/admin",
    path: "/add-service",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
        mt={"8px"}
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Manage API",
    layout: "/admin",
    path: "/manage-api",
    icon: (
      <Icon as={MdLock} width="20px" height="20px" color="inherit" mt={"8px"} />
    ),
    component: ManageApi,
  },
  {
    name: "Service Logs",
    layout: "/admin",
    path: "/service-logs",
    icon: (
      <Icon
        as={MdAnalytics}
        width="20px"
        height="20px"
        color="inherit"
        mt={"8px"}
      />
    ),
    component: ServiceLogs,
  },
  {
    name: "Data Logs",
    layout: "/admin",
    icon: (
      <Icon
        as={MdBarChart}
        width="20px"
        height="20px"
        color="inherit"
        mt={"8px"}
      />
    ),
    path: "/data-logs",
    component: DataTables,
  },
  {
    name: "Logs",
    layout: "/admin",
    icon: (
      <Icon
        as={MdBarChart}
        width="20px"
        height="20px"
        color="inherit"
        mt={"8px"}
      />
    ),
    path: "/logs",
    component: Logs,
  },
  {
    name: "Create API",
    layout: "/admin",
    path: "/create-api",
    component: CreateApiModal,
  },
  {
    name: "Update API",
    layout: "/admin",
    path: "/update-api",
    component: UpdateApi,
  },
  {
    name: "Add Service",
    layout: "/admin",
    icon: (
      <Icon
        as={MdBarChart}
        width="20px"
        height="20px"
        color="inherit"
        mt={"8px"}
      />
    ),
    path: "/new-service",
    component: AddService,
  },
  {
    name: "Add Application",
    layout: "/admin",
    icon: (
      <Icon
        as={MdBarChart}
        width="20px"
        height="20px"
        color="inherit"
        mt={"8px"}
      />
    ),
    path: "/new-application",
    component: AddApplicationDetails,
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: (
  //     <Icon
  //       as={MdPerson}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //       mt={"8px"}
  //     />
  //   ),
  //   component: Profile,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon as={MdHome} width="20px" height="20px" color="inherit" mt={"8px"} />
    ),
    component: SignIn,
  }

];

export default routes;
