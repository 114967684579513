import { useEffect, useState } from "react";
import {
  Box,
  Button,
  SimpleGrid,
  Grid,
  Text,
  useColorModeValue,
  Textarea,
} from "@chakra-ui/react";
import { FormControl, FormLabel, InputGroup } from "@chakra-ui/react";
import { Checkbox,Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CreateServices } from "../../../../Redux/actions/Services/Services";
import SelectDropDown from "views/admin/Analytics/Components/SelectDropDown";
import { getApplications } from "Redux/actions/Application";
import CustomToolTip from "components/Tooltip/CustomTooltip";
import { useHistory } from "react-router-dom";

export default function AddService() {
  const dispatch = useDispatch();
  const history = useHistory();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const applicationList = useSelector(
    (state) => state?.Application?.applications
  );
  console.log("applicationList", applicationList);
  const successAddedService = useSelector((state) => state?.Service?.createServices?.result);
  console.log("successAddedService", successAddedService);
  const serviceAdded = useSelector((state) => state?.Service?.createServices)
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [context, setContext] = useState(null);
  const [url, setUrl] = useState(null);
  const [contact, setContact] = useState(null);
  const [checkedItems, setCheckedItems] = useState(false);

  const [selectedValue, setSelectedValue] = useState(true);
  const [deploymentTypeValue, setDeploymentTypeValue] = useState("M");
  const [serviceType, setServiceType] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [contextError, setContextError] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const [applicationListData, setApplicationListData] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const [applicationName, setApplicationName] = useState(null)
  const [applicationError, setApplicationError] = useState(false)

  const tooltipText = (
    <div className="tooltip-conatiner">
      <p>
        Enabling metrics is essential for tracking system performance and user
        behavior. It aids in resource optimization and user experience
        improvement. However, it may introduce some resource overhead and
        storage costs. Careful planning is crucial to strike the right balance.
      </p>
    </div>
  );

  useEffect(() => {
    dispatch(getApplications());
  }, [dispatch]);

  useEffect(() => {
    if (applicationList && applicationList.length > 0) {
      const mappedApplications = applicationList?.map((item) => {
        return {
          label: item.name,
          value: item.name,
          name: item.id,
        };
      });
      setApplicationListData(mappedApplications);
      console.log("mappedApplications", mappedApplications);
    }
  }, [applicationList]);

  useEffect(() => {
    if (successAddedService) {
      console.log("successAddedServicelogged");
      setName(null);
      setDescription(null);
      setUrl(null);
      setContext(null);
      setContact(null);
      setCheckedItems(false);
      setSelectedValue(true);
      setServiceType(null);
      setApplicationName(null)
      setDeploymentTypeValue("M");
    }
  }, [successAddedService]);

  const onApplicationChange = (value, label) => {
    console.log("value, label",value, label);
    setApplicationId(label?.name);
    setApplicationName(value)
    setApplicationError(false)
  };
  const nameHandler = (e) => {
    const inputValue = e.target.value;
    setName(inputValue);
    if (/^\s/.test(inputValue)) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };
  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };
  const urlHandler = (e) => {
    setUrl(e.target.value);
    setUrlError(false);
  };
  const contactHandler = (e) => {
    setContact(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(e.target.value)) {
      setContactError(true);
    } else {
      setContactError(false);
    }
  };
  const contextHandler = (e) => {
    const inputValue = e.target.value;
    setContext(inputValue);
    if (/^\s/.test(inputValue)) {
      setContextError(true)
    }
    else if (inputValue.trim() === "") {
      setContext(null)
      setContextError(false)
    }
    if (!inputValue.startsWith('/')) setContextError(true)
    else setContextError(false)
  };

  const checkboxHandler = (e) => {
    console.log("hello e.target.checked", e.target.checked);
    setCheckedItems(e.target.checked);
    setCheckboxError(false);
  };

  const selectHandle = (value,label) => {
    console.log("hello11 selected", value,label);
    setSelectedValue(value);
  };
  console.log("deployementType",deploymentTypeValue);
  const deploymentTypeHandle = (value,label) => {
    console.log("hello selected", value);
    setDeploymentTypeValue(value);
  };

  const ServiceTypeHandle = (value) => {
    console.log("hello serviceType", value);
    setServiceType(value);
  };
  
  const statusList = [
    {
      label: "Active",
      value: true,
    },
    {
      label: "InActive",
      value: false,
    },
  ];
  const deployTypeList = [
    {
      label: "Automatic",
      value: "A",
    },
    {
      label: "Manual",
      value: "M",
    },
  ];
  const ServiceTypeList = [
    {
      label: "Web",
      value: "web",
    },
    {
      label: "Mobile",
      value: "Moile",
    },
  ];
  const nameValidations = () => {
    if (name?.trim()) {
      setNameError(false);
      return true;
    } else {
      setNameError(true);
      return false;
    }
  };
  const contextValidations = () => {
    if (context !== "" && context !== null && context !== undefined) {
      setContextError(false);
      return true;
    } else {
      setContextError(true);
      return false;
    }
  };
  const contactValidations = () => {
    if (contact !== "" && contact !== null && contact !== undefined && !contactError) {
      setContactError(false);
      return true;
    } else {
      setContactError(true);
      return false;
    }
  };

  const urlValidations = () => {
    if (url !== "" && url !== null && url !== undefined) {
      setUrlError(false);
      return true;
    } else {
      setUrlError(true);
      return false;
    }
  };

  const checkboxValidations = () => {
    if (checkedItems === true) {
      setCheckboxError(false);
      return true;
    } else {
      setCheckboxError(true);
      return false;
    }
  };

  const applicationValidation = () => {
    if (applicationId === null || applicationId === "" || applicationId === undefined) {
      setApplicationError(true)
      return false;
    }
    else {
      setApplicationError(false)
      return true;
    }
  }

  const checkValidations = () => {
    if (
      (nameValidations() === true) &
      (urlValidations() === true) &
      (contactValidations() === true) &
      (checkboxValidations() === true) & 
      (applicationValidation() === true)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const value = checkValidations();

    if (value === true) {
      let data = {
        serviceName: name,
        description: description,
        uri: url,
        contextPath: contextError ? null : context,
        email: contact,
        isMetricsEnabled: checkedItems,
        active: selectedValue,
        serviceType: serviceType,
        deploymentType: deploymentTypeValue,
        cronTime: "23:00",
        everyWeekDay: true,
      };
      console.log("hello create api", data);
      dispatch(CreateServices(data, applicationId));
    }
    // else ()
  };
  const onBackHandle = () => {
    history.push("/admin/add-service");
  };
  return (
    <Box mt={{ base: "300px", md: "80px" }}>
      <p className="back-button" onClick={onBackHandle}>
        &lt;Back
      </p>
      <Box backgroundColor={"white"} borderRadius={"20px"}>
        <Grid
          mb="20px"
          // gridTemplateColumns={{ xl: "repeat(1, 1fr)", "2xl": "1fr 0.46fr" }}
          gap={{ base: "20px", xl: "20px" }}
          display={{ base: "block", xl: "grid" }}
        >
          <SimpleGrid
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: "10px", xl: "10px" }}
          >
            <FormControl padding={"20px"} borderRadius={"20px"}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Application<Text color={brandStars}>*</Text>
              </FormLabel>
              <SelectDropDown
                showSearch
                style={{ width: "100%" }}
                placeholder="Application"
                value={applicationName}
                onChange={onApplicationChange}
                options={applicationListData}
              ></SelectDropDown>
              {applicationError ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "20px",
                      fontSize: "11px",
                    }}
                  >
                    ** Please select Application
                  </p>
                ) : (
                  <p></p>
                )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mt="20px"
              >
                Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                maxLength={35}
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="login"
                mb="24px"
                fontWeight="500"
                size="lg"
                className="common-input-fields"
                value={name}
                onChange={nameHandler}
              />
              {nameError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter Name
                </p>
              ) : (
                <p></p>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="10px"
              >
                URI<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="https://gateway-chmd.theretailinsightsdemos.com"
                  mb="24px"
                  size="lg"
                  maxLength={245}
                  type={"text"}
                  variant="auth"
                  className="common-input-fields"
                  value={url}
                  onChange={urlHandler}
                />
              </InputGroup>
              {urlError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter URL
                </p>
              ) : (
                <p></p>
              )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mt="10px"
              >
                Context-path
              </FormLabel>
              <Input
                isRequired={true}
                type="text"
                placeholder="/qry/login"
                className="common-input-fields"
                value={context}
                maxLength={100}
                onChange={contextHandler}
              />
              {contextError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter context-path{" "}
                  <br/>
                  Note:- context-path won't be included if it doesn't match the input criteria
                </p>
              ) : (
                <p></p>
              )}
            </FormControl>
            <FormControl padding={"20px"}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Contact Person<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                maxLength={100}
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="mail@simmmple.com"
                mb="24px"
                fontWeight="500"
                className="common-input-fields"
                size="lg"
                value={contact}
                onChange={contactHandler}
              />
              {contactError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter contact person
                </p>
              ) : (
                <p></p>
              )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                mt="20px"
              >
                Deployment Type<Text color={brandStars}>*</Text>
              </FormLabel>
              <SelectDropDown
                style={{ width: "100%" }}
                placeholder="Deployment Type"
                value={deploymentTypeValue}
                onChange={deploymentTypeHandle}
                options={deployTypeList}
              ></SelectDropDown>

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                mt="20px"
              >
                Service Type<Text color={brandStars}>*</Text>
              </FormLabel>
              <SelectDropDown
                showSearch
                style={{ width: "100%" }}
                placeholder="Service Type"
                value={serviceType}
                onChange={ServiceTypeHandle}
                options={ServiceTypeList}
              ></SelectDropDown>

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                mt="20px"
              >
                Status<Text color={brandStars}>*</Text>
              </FormLabel>
              <SelectDropDown
                style={{ width: "100%" }}
                onChange={selectHandle}
                placeholder={"Select Status"}
                options={statusList}
                value={selectedValue}
              />
              <FormControl mt={"20px"}>
                <div className="checkbox-info-container">
                  <Checkbox
                    size="md"
                    mt="8px"
                    checked={checkedItems}
                    onChange={checkboxHandler}
                  >
                    Enable Metrics ?
                  </Checkbox>
                  <CustomToolTip text={tooltipText} />
                </div>
                {checkboxError ? (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "10px",
                      fontSize: "11px",
                    }}
                  >
                    ** Please Enabled Metrics
                  </p>
                ) : (
                  <p></p>
                )}
              </FormControl>
            </FormControl>
          </SimpleGrid>
          <FormControl padding={"0px 20px"} marginTop={"-35px"}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Description
            </FormLabel>
            <Textarea
              isRequired={true}
              border={"1px solid"}
              borderColor={"#A0AEC0"}
              maxLength={250}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Description of an Service"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={description}
              onChange={descriptionHandler}
            />
          </FormControl>
          <FormControl display="flex" justifyContent="center" marginTop="10px">
            <Button
              //   onClick={onSigninClick}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="15%"
              h="50"
              mb="24px"
              onClick={submitHandler}
            >
              Submit
            </Button>
          </FormControl>
        </Grid>
      </Box>
    </Box>
  );
}
