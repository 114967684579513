// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
  color: black;
  font-weight: 700;
  text-decoration: underline;
  /* margin: -18px 0px 10px 4px; */
  cursor: pointer;
  display: inline;
}
.ant-select-selector .ant-select-selection-search-input {
  height: 43px !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/manageApi/components/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,0BAA0B;EAC1B,gCAAgC;EAChC,eAAe;EACf,eAAe;AACjB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".back-button {\n  color: black;\n  font-weight: 700;\n  text-decoration: underline;\n  /* margin: -18px 0px 10px 4px; */\n  cursor: pointer;\n  display: inline;\n}\n.ant-select-selector .ant-select-selection-search-input {\n  height: 43px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
