import React from "react";
import { Table } from "antd";

const DetailsTable = ({ onRow, columns, dataSource }) => {
  return (
    <div className="details-table">
      <Table pagination={{ pageSize: 10 }} onRow={onRow} columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default DetailsTable;
