import axios from "axios";
import * as types from "../actionTypes";
import { toast } from "components/Toast/Toast";

export const timePickerData = (value, name) => ({
  type: types.TIME_DATA_VALUES,
  value,
  name,
});

const dataLogsLoading = () => ({
  type: types.DATA_LOGS_LOADING,
});
const dataLogsSuccess = (data) => ({
  type: types.DATA_LOGS_SUCCESS,
  payload: data,
});
const dataLogsFail = (error) => ({
  type: types.DATA_LOGS_FAIL,
  payload: error,
});

const dataLogByIdLoading = () => ({
  type: types.DATA_LOGS_BY_ID_LOADING,
});
const dataLogByIdSuccess = (data) => ({
  type: types.DATA_LOGS_BY_ID_SUCCESS,
  payload: data,
});
const dataLogByIdFail = (error) => ({
  type: types.DATA_LOGS_BY_ID_FAIL,
  payload: error,
});

let newUrl = process.env.REACT_APP_BASE_NEW_URL;

export const getDataLogsByFilter = (data) => {
  return (dispatch) => {
    dispatch(dataLogsLoading());
    axios
      .post(`${newUrl}metrics/api/v1/logs?pageNo=0&pageSize=100`, data)
      .then((resp) => {
        dispatch(dataLogsSuccess(resp.data));
        if (resp.data?.content?.length === 0) {
          toast.error("No Data Found");
        }
      })
      .catch((error) => {
        dispatch(dataLogsFail(error));
      });
  };
};

export const getDataLogsById = (id) => {
  return (dispatch) => {
    dispatch(dataLogByIdLoading());
    axios
      .get(`${newUrl}metrics/api/v1/log/${id}`)
      .then((resp) => {
        dispatch(dataLogByIdSuccess(resp.data));
        toast.error("No Data Found");
      })
      .catch((error) => {
        dispatch(dataLogByIdFail(error));
      });
  };
};
