import React from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import DetailsTable from "../../dataTables/components/DetailsTable";

const DetailsCard = (props) => {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const smallTextColor = useColorModeValue("secondaryGray.500", "white");

  return (
    <div>
      <Card
        p="20px"
        align="center"
        direction="column"
        w="100%"
        h={"440px"}
        {...rest}
      >
        <Flex
          textAlign="left"
          px={{ base: "0px", "2xl": "10px" }}
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          mb="8px"
        >
          <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
            {props.cardName}
            <Text
              color={smallTextColor}
              fontSize="13px"
              fontWeight="400"
              mt="4px"
            >
              {props.shortCardName}
            </Text>
          </Text>
        </Flex>
        <DetailsTable
          rowSelection={props.rowSelection}
          columns={props.columns}
          dataSource={props.dataSource}
        />
      </Card>
    </div>
  );
};

export default DetailsCard;
