import axios from "axios";
import * as types from "../actionTypes";

const metricsLoading = () => ({
  type: types.DASHBOARD_METRICS_LOADING,
});
const metricsSuccess = (data) => ({
  type: types.DASHBOARD_METRICS_SUCCESS,
  payload: data,
});
const metricsFail = (error) => ({
  type: types.DASHBOARD_METRICS_FAIL,
  payload: error,
});

const appDetailsLoading = () => ({
  type: types.DASHBOARD_APP_DETAILS_LOADING,
});
const appDetailsSuccess = (data) => ({
  type: types.DASHBOARD_APP_DETAILS_SUCCESS,
  payload: data,
});
const appDetailsFail = (error) => ({
  type: types.DASHBOARD_APP_DETAILS_FAIL,
  payload: error,
});

let newUrl = process.env.REACT_APP_BASE_NEW_URL;
let getUrl = process.env.REACT_APP_BASE_URL_GET;

export const dashboardMetrics = (data) => {
  return (dispatch) => {
    dispatch(metricsLoading());
    axios
      .post(`${newUrl}metrics/api/v1/graph`, data)
      .then((resp) => {
        dispatch(metricsSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(metricsFail(error));
      });
  };
};

export const dashboardAppDetailsMetrics = (data) => {
  return (dispatch) => {
    dispatch(appDetailsLoading());
    axios
      .get(`${getUrl}qry/api/v1/appDetail`, data)
      .then((resp) => {
        dispatch(appDetailsSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(appDetailsFail(error));
      });
  };
};
