import "./ServicesDetails.css";
import { useDispatch, useSelector } from "react-redux";
import CustomNavigationMenu from "./NavigationMenu";
import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Button, Input } from "@chakra-ui/react";
import SelectDropDown from "views/admin/Analytics/Components/SelectDropDown";
import { Modal, Tooltip } from "antd";
import { EditServiceToggle } from "Redux/actions/Services/Services";
import { EditServices } from "Redux/actions/Services/Services";
import { selectedServiceId } from "Redux/actions/Services/Services";
import { toast } from "components/Toast/Toast";

const ServicesDetails = () => {
  const dispatch = useDispatch();
  const AppServiceData = useSelector((state) => state?.Service?.serviceApplication?.result?.content);
  const ServiceData = useSelector((state) => state?.Service?.serviceData);
  console.log("AppServiceData",AppServiceData);
  console.log("ServiceData", ServiceData);
  const editToggle = useSelector((state) => state?.Service?.editServiceToggleButton)
  console.log("editToggle",editToggle);
  const defaultSelectedServiceId = useSelector((state) => state?.Service?.selectedId)
  console.log("defaultSelectedServiceId",defaultSelectedServiceId);

  useEffect(() => {
    if (AppServiceData && AppServiceData?.length > 0) {
      dispatch(selectedServiceId(AppServiceData?.[0]?.services?.[0]?.id))
    }
  },[AppServiceData])

  const [editServiceModal, setEditServiceModal] = useState(false)
  const [serviceName, setName] = useState(null)
  const [serviceType, setType] = useState(null)
  const [description, setDiscription] = useState(null)
  const [uri, setUri] = useState(null)
  const [contextPath, setContextPath] = useState(null)
  const [email, setContactPerson] = useState(null)
  const [active, setStatus] = useState(null)
  const [apiObject, setApiObject] = useState({})
  const [contactError, setContactError] = useState(false);

  const statusList = [
    {
      label: "Active",
      value: true,
    },
    {
      label: "InActive",
      value: false,
    },
  ];
  const ServiceTypeList = [
    {
      label: "Web",
      value: "web",
    },
    {
      label: "Mobile",
      value: "Moile",
    },
  ];

  const onEditClick = () => {
    dispatch(EditServiceToggle(true))
    setName(ServiceData?.serviceName)
    setType(ServiceData?.serviceType)
    setDiscription(ServiceData?.description)
    setUri(ServiceData?.uri)
    setContextPath(ServiceData?.contextPath)
    setContactPerson(ServiceData?.email)
    setStatus(ServiceData?.active)
    setApiObject({})
  }
  useEffect(() => {
      if (serviceName === ServiceData?.serviceName || serviceName === null || serviceName === undefined || serviceName === "") {
        const { serviceName, ...rest } = apiObject;
        setApiObject(rest);
      }
      else {
        setApiObject((prevObject) => ({...prevObject,serviceName}))
      }
    },[serviceName])
    

    useEffect(() => {
      if (serviceType === ServiceData?.serviceType || serviceType === null || serviceType === undefined || serviceType === "") {
        const { serviceType, ...rest } = apiObject;
        setApiObject(rest);
      }
      else {
        setApiObject((prevObject) => ({...prevObject,serviceType}))
      }
    },[serviceType])


    useEffect(() => {
      if (description === ServiceData?.description || description === null || description === undefined || description === "") {
        const { description, ...rest } = apiObject;
        setApiObject(rest);
      }
      else {
        setApiObject((prevObject) => ({...prevObject,description}))
      }
    },[description])
    
    useEffect(() => {
    if (active === ServiceData?.active || active === null || active === undefined || active === "") {
      const { active, ...rest } = apiObject;
      setApiObject(rest);
    }
    else {
      setApiObject((prevObject) => ({...prevObject,active}))
    }
  },[active])
  useEffect(() => {
    if (uri === ServiceData?.uri || uri === null || uri === undefined || uri === "") {
      const { uri, ...rest } = apiObject;
      setApiObject(rest);
    }
    else {
      setApiObject((prevObject) => ({...prevObject,uri}))
    }
  },[uri])
  useEffect(() => {
    if (contextPath === ServiceData?.contextPath || contextPath === null || contextPath === undefined || contextPath === "" || contextError) {
      const { contextPath, ...rest } = apiObject;
      setApiObject(rest);
    }
    else {
      setApiObject((prevObject) => ({...prevObject,contextPath}))
    }
  },[contextPath])
  useEffect(() => {
    if (email === ServiceData?.email || email === null || email === undefined || email === "") {
      const { email, ...rest } = apiObject;
      setApiObject(rest);
      setContactError(false);
    }
    else {
      setApiObject((prevObject) => ({...prevObject,email}))
    }
  },[email])
  console.log("apiobject",apiObject);
  const contactValidations = () => {
    if (email === ServiceData?.email || email !== "" || email !== null || email !== undefined || !email) {
      setContactError(false);
      return true;
    } else {
      setContactError(true);
      return false;
    }
  };
  const UpdateHandle = () => {
    const isObjectEmpty = Object.keys(apiObject).length === 0;
    let validated = contactValidations();
    if (isObjectEmpty === true) {
      toast.error("Please change the data to Update")
      setEditServiceModal(false)
      setContactError(false)
    }
    else if (validated === true) {
      setEditServiceModal(true)
      setContactError(false);
    }
    else {
      setEditServiceModal(false)
      setContactError(true);
    }
  }

  const onCloseHandle = () => {
    dispatch(EditServiceToggle(false))
    setContactError(false);
  }

  const onEditOk = () => {
    setEditServiceModal(false)
    dispatch(EditServiceToggle(false))
    dispatch(EditServices(defaultSelectedServiceId,apiObject,ServiceData))
  }

  const onEditCancel = () => {
    setEditServiceModal(false)
  }

  const nameHandler = (e) => {
    setName(e.target.value)
  }
  const typeHandler = (value) => {
    setType(value)
  }
  const discriptionHandler = (e) => {
    setDiscription(e.target.value)
  }
  const uriHandler = (e) => {
    setUri(e.target.value)
  }
  const [contextError, setContextError] = useState(false)
  const contextPathHandler = (e) => {
    const inputValue = e.target.value;
    setContextPath(inputValue);
    if (/^\s/.test(inputValue)) {
      setContextError(true)
    }
    else if (inputValue.trim() === "") {
      setContextPath(null)
      setContextError(false)
    }
    if (!inputValue.startsWith('/')) setContextError(true)
    else setContextError(false)
  }
  const contactPersonHandler = (e) => {
    setContactPerson(e.target.value);
    const emailRegex = /^[^\sss@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(e.target.value)) {
      setContactError(true);
      setContactPerson(null)
    } else {
      setContactError(false);
    }
  }
  const statusHandler = (value) => {
    setStatus(value)
  }

  return (
    <div className="service-main-container">
      <div className="service-name-container">
        <p className="service-name">All Services</p>
        <CustomNavigationMenu />
      </div>
      <div className="service-break"></div>
      {ServiceData ? (
        <div className="service-name-table">
          <div className='name-edit-container'>
          {editToggle ?
          <Tooltip placement="top" title={"Save Service"}>
            <SaveOutlined onClick={UpdateHandle} className='edit-icon'/>
          </Tooltip>
            : AppServiceData?.length > 0 ?
            <Tooltip placement="top" title={"Edit Service"}>
            <EditOutlined onClick={onEditClick} className='edit-icon' />
            </Tooltip>
            : <></>
          }
          {editToggle ? <Tooltip placement="top" title={"Close"}>
          <CloseOutlined onClick={onCloseHandle} className="close-icon" />
          </Tooltip> : <></>}
          </div>
          <p className="selected-service-name">{ServiceData?.serviceName}</p>
          <>
            <div className="service-container">
              <div className="service-details">
                <span className="api-label">Name :</span>
                <span className="api-details">
                  {editToggle ? <Input
                    fontSize="sm"
                    maxLength={35}
                    type="text"
                    placeholder="Application Name"
                    fontWeight="500"
                    size="lg"
                    value={serviceName}
                    onChange={nameHandler}
                  /> : ServiceData ? ServiceData?.serviceName : null}
                </span>
              </div>

              <div className="service-details">
                <span className="api-label">Type :</span>
                <span className="api-details">
                  {editToggle ? 
                  <SelectDropDown
                  style={{ width: "100%" }}
                  placeholder="Deployment Type"
                  value={serviceType}
                  onChange={typeHandler}
                  options={ServiceTypeList}
                ></SelectDropDown> : ServiceData ? ServiceData?.serviceType : null}
                </span>
              </div>

              <div className="service-details">
                <span className="api-label">Modified At :</span>
                <span className="api-details">
                  {ServiceData ? ServiceData?.updatedAt : null}
                </span>
              </div>
            </div>

            <div className="service-container">
              <div className="service-details">
                <span className="api-label">Description :</span>
                <span className="api-details">
                  {editToggle ? <Input
                    fontSize="sm"
                    type="text"
                    placeholder="Application Name"
                    fontWeight="500"
                    size="lg"
                    maxLength={254}
                    value={description}
                    onChange={discriptionHandler}
                  /> : ServiceData ? ServiceData?.description : null}
                </span>
              </div>
            </div>
            <div className="service-container">
              <div className="service-details">
                <span className="api-label">URI :</span>
                <span className="api-details">
                  {editToggle ? <Input
                    fontSize="sm"
                    type="text"
                    placeholder="Application Name"
                    fontWeight="500"
                    size="lg"
                    value={uri}
                    onChange={uriHandler}
                  /> : ServiceData ? ServiceData?.uri : null}
                </span>
              </div>
              <div className="service-details">
                <span className="api-label">Context Path :</span>
                <span className="api-details">
                  {editToggle ? <Input
                    fontSize="sm"
                    type="text"
                    placeholder="/qry/login"
                    fontWeight="500"
                    size="lg"
                    value={contextPath}
                    onChange={contextPathHandler}
                  /> : ServiceData ? ServiceData?.contextPath : null}
                  {contextError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter context-path{" "}
                  <br/>
                  Note:- context-path won't be included if it doesn't match the input criteria
                </p>
              ) : (
                <p></p>
              )}
                </span>
              </div>
            </div>

            <div className="service-container">
              <div className="service-details">
                <span className="api-label">Contact Perosn :</span>
                <span className="api-details">
                  {editToggle ? <Input
                    fontSize="sm"
                    type="text"
                    placeholder="Application Name"
                    fontWeight="500"
                    size="lg"
                    value={email}
                    onChange={contactPersonHandler}
                  /> : ServiceData ? ServiceData?.email : null}
                  {contactError ? (
                <p
                  style={{
                    color: "red",
                    marginBottom: "5px",
                    fontSize: "11px",
                  }}
                >
                  ** Please enter contact person
                </p>
              ) : (
                <p></p>
              )}
                </span>
              </div>
              <div className="service-details">
                <span className="api-label">Status :</span>
                <span className="api-details">
                  {editToggle ? 
                  <SelectDropDown
                  style={{ width: "100%" }}
                  placeholder="Deployment Type"
                  value={active}
                  onChange={statusHandler}
                  options={statusList}
                ></SelectDropDown> : ServiceData
                    ? ServiceData?.active
                      ? "Active"
                      : "In Active"
                    : null}
                </span>
              </div>
            </div>

            <div className="service-container">
              <div className="service-details">
                <span className="api-label">Deployment :</span>
                <span className="api-details">
                  {ServiceData ? ServiceData?.deploymentType : null}
                </span>
              </div>
            </div>

            {ServiceData?.deploymentType === "A" ? (
              <div className="service-container">
                <div className="service-details">
                  <span className="api-label">CRON Time :</span>
                  <span className="api-details">
                    {ServiceData ? ServiceData?.cronTime : null}
                  </span>
                </div>
                <div className="service-details">
                  <span className="api-label">Every Week Day :</span>
                  <span className="api-details">
                    {ServiceData ? ServiceData?.everyWeekDay : null}
                  </span>
                </div>
              </div>
            ) : null}
          </>
        </div>
      ) : (
        <p className="service-name">Select a service to view details.</p>
      )}

      <div>
      <Modal title="Edit Confirmation" open={editServiceModal} onCancel={onEditCancel}>
        <>
        <div>
          <p>Do you want to Update the Changes?</p>
        </div>
        <div className='modal-buttons-container'>
          <Button onClick={onEditOk}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="15%"
          h="40px"
          mr="10px"
          >Submit</Button>
          <Button onClick={onEditCancel}
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="15%"
          h="40px"
          >Cancel</Button>
        </div>
        </>
      </Modal>
        </div>
    </div>
  );
};

export default ServicesDetails;
