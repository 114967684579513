import React from 'react'
import {Select} from "antd"
import "./CustomDropDown.css"
const CustomDropDown = ({onChange,defaultValue,value,dropDownValue,placeholder,allowClear}) => {
 // console.log("dropDownValue",dropDownValue, typeof dropDownValue);
  return (
    <div className={dropDownValue === "GET" ? "get" : 
                    dropDownValue === "POST" ?  "post" :
                    dropDownValue === "PUT" ? "put" :
                    dropDownValue === "PATCH" ? "patch" : 
                    dropDownValue === "DELETE" ? "delete" : "get"}>
        <Select
          onChange={onChange}
          style={{ width: "100%" }}
          id="drop"
          defaultValue={defaultValue}    
          value={value}
          placeholder={placeholder}
          allowClear={allowClear}
        >
          <option
            style={{ color: "#007f31", fontWeight: "800" }}
            color={
              dropDownValue === "GET"
                ? "#007f31"
                : dropDownValue === "POST"
                ? "#b38416"
                : dropDownValue === "PUT"
                ? "#1863be"
                : dropDownValue === "PATCH"
                ? "#6b409d"
                : dropDownValue === "DELETE"
                ? "#9a332a"
                : "#007f31"
            }
            value="GET"
            name="GET"
          >
            GET
          </option>
          <option
            style={{ color: "#b38416", fontWeight: "800"  }}
            color={
              dropDownValue === "GET"
                ? "#007f31"
                : dropDownValue === "POST"
                ? "#b38416"
                : dropDownValue === "PUT"
                ? "#1863be"
                : dropDownValue === "PATCH"
                ? "#6b409d"
                : dropDownValue === "DELETE"
                ? "#9a332a"
                : "#007f31"
            }
            value="POST"
            name="POST"
          >
            POST
          </option>
          <option
            style={{ color: "#1863be", fontWeight: "800"  }}
            color={
              dropDownValue === "GET"
                ? "#007f31"
                : dropDownValue === "POST"
                ? "#b38416"
                : dropDownValue === "PUT"
                ? "#1863be"
                : dropDownValue === "PATCH"
                ? "#6b409d"
                : dropDownValue === "DELETE"
                ? "#9a332a"
                : "#007f31"
            }
            value="PUT"
            name="PUT"
          >
            PUT
          </option>
          <option
            style={{ color: "#6b409d", fontWeight: "800"  }}
            color={
              dropDownValue === "GET"
                ? "#007f31"
                : dropDownValue === "POST"
                ? "#b38416"
                : dropDownValue === "PUT"
                ? "#1863be"
                : dropDownValue === "PATCH"
                ? "#6b409d"
                : dropDownValue === "DELETE"
                ? "#9a332a"
                : "#007f31"
            }
            value="PATCH"
            name="PATCH"
          >
            PATCH
          </option>
          <option
            style={{ color: "#9a332a", fontWeight: "800"  }}
            color={
              dropDownValue === "GET"
                ? "#007f31"
                : dropDownValue === "POST"
                ? "#b38416"
                : dropDownValue === "PUT"
                ? "#1863be"
                : dropDownValue === "PATCH"
                ? "#6b409d"
                : dropDownValue === "DELETE"
                ? "#9a332a"
                : "#007f31"
            }
            value="DELETE"
            name="DELETE"
          >
            DELETE
          </option>
        </Select>
      </div>
  )
}

export default CustomDropDown