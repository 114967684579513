import axios from "axios";
import * as types from "../actionTypes";
import { toast } from "components/Toast/Toast";

const GetApplicationLoading = () => ({
  type: types.GET_APPLICATIONS_LOADING,
});
const GetApplicationSuccess = (data) => ({
  type: types.GET_APPLICATIONS_SUCCESS,
  payload: data,
});
const GetApplicationFail = (error) => ({
  type: types.GET_APPLICATIONS_FAIL,
  payload: error,
});

const CreateApplicationLoading = () => ({
  type: types.CREATE_APPLICATIONS_LOADING,
});
const CreateApplicationSuccess = (data) => ({
  type: types.CREATE_APPLICATIONS_SUCCESS,
  payload: data,
});
const CreateApplicationFail = (error) => ({
  type: types.CREATE_APPLICATIONS_FAIL,
  payload: error,
});

const EditApplicationLoading = () => ({
  type: types.EDIT_APPLICATION_LOADING,
});
const EditApplicationSuccess = (data) => ({
  type: types.EDIT_APPLICATION_SUCCESS,
  payload: data,
});
const EditApplicationFail = (error) => ({
  type: types.EDIT_APPLICATION_FAIL,
  payload: error,
});

let url = process.env.REACT_APP_BASE_URL_GET;
let postUrl = process.env.REACT_APP_BASE_URL_POST;

export const getApplications = () => {
  return (dispatch) => {
    dispatch(GetApplicationLoading());
    axios
      .get(`${url}qry/api/v1/applications`)
      .then((resp) => {
        dispatch(GetApplicationSuccess(resp?.data?.result?.reverse()));
      })
      .catch((error) => {
        dispatch(GetApplicationFail(error));
      });
  };
};

export const createApplications = (data) => {
  return (dispatch) => {
    dispatch(CreateApplicationLoading());
    axios
      .post(`${postUrl}cmd/api/v1/app`, data)
      .then((resp) => {
        dispatch(CreateApplicationSuccess(resp.data));
        toast.success("Application created successfully");
      })
      .catch((error) => {
        dispatch(CreateApplicationFail(error));
        if (error?.response?.status === 409) {
          toast.error(error?.response?.data?.detail);
        }
        else {
          toast.error("Failed to create Application");
        }
      });
  };
};

export const editApplications = (id,data) => {
  return (dispatch) => {
    dispatch(EditApplicationLoading());
    axios
      .patch(`${postUrl}cmd/api/v1/app/${id}`, data)
      .then((resp) => {
        dispatch(EditApplicationSuccess(resp.data));
        dispatch(getApplications())
        toast.success("Application edited successfully");
      })
      .catch((error) => {
        dispatch(EditApplicationFail(error));
        toast.error("Failed to edit Application");
      });
  };
};
