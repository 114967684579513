import React from "react";
import "./ConsumerGatewayLogs.css";
import Card from "components/card/Card";
import { Text } from "@chakra-ui/react";

const ConsumerGatewayLogs = ({
  Imagesrc,
  backgroundColor,
  smallCardBgColor,
  smallCardName,
}) => {
  return (
    <Card
      backgroundColor={backgroundColor}
      justifyContent="center"
      direction="column"
      h="25vh"
      w="100%"
    >
      <div className="gateway-main-container">
        <div style={{ background: smallCardBgColor }} className="container-one">
          <Text
            style={{ transform: "rotate(90deg)" }}
            textAlign="center"
            fontSize="md"
            fontWeight="600"
          >
            {smallCardName}
          </Text>
        </div>
        <div className="container-two">
          <p>No log for this request</p>
          <br />
          <p>
            Please check or{" "}
            <span className="configure-text">configure logging mode</span>{" "}
            (client)
          </p>
        </div>
        <div className="container-three">
          <img className="arrow-image" src={Imagesrc} alt="RightArrow" />
        </div>
        <div className="container-four">
          <p>No log for this request</p>
          <br />
          <p>
            Please check or{" "}
            <span className="configure-text">configure logging mode</span>{" "}
            (proxy)
          </p>
        </div>
      </div>
    </Card>
  );
};

export default ConsumerGatewayLogs;
