import axios from "axios";
import * as types from "../actionTypes";
import { toast } from "components/Toast/Toast";
import { Services } from "./Services/Services";

export const editApiToggle = (data) => ({
  type: types.EDIT_API_MODAL_TOGGLE,
  payload: data
})
const deployApiLoading = () => ({
  type: types.DEPLOY_API_LOADING,
});
const deployApiSuccess = (data) => ({
  type: types.DEPLOY_API_SUCCESS,
  payload: data,
});
const deployApiFail = (error) => ({
  type: types.DEPLOY_API_FAIL,
  payload: error,
});
const createManageApiLoading = () => ({
  type: types.CREATE_MANAGE_API_DATA_LOADING,
});
const createManageApiSuccess = (data) => ({
  type: types.CREATE_MANAGE_API_DATA_SUCCESS,
  payload: data,
});
const createManageApiFail = (error) => ({
  type: types.CREATE_MANAGE_API_DATA_FAIL,
  payload: error,
});
const getServiceListLoading = () => ({
  type: types.GET_SERVICES_LIST_LOADING,
});
const getServiceListSuccess = (data) => ({
  type: types.GET_SERVICES_LIST_SUCCESS,
  payload: data,
});
const getServiceListFail = (error) => ({
  type: types.GET_SERVICES_LIST_FAIL,
  payload: error,
});
const editApiLoading = () => ({
  type: types.EDIT_API_LOADING,
});
const editApiSuccess = (data) => ({
  type: types.EDIT_API_SUCCESS,
  payload: data,
});
const editApiFail = (error) => ({
  type: types.EDIT_API_FAIL,
  payload: error,
});
const testApiLoading = () => ({
  type: types.TEST_API_LOADING,
});
const testApiSuccess = (data) => ({
  type: types.TEST_API_SUCCESS,
  payload: data,
});
const testApiFail = (error) => ({
  type: types.TEST_API_FAIL,
  payload: error,
});

let url = process.env.REACT_APP_BASE_URL_POST;
let getUrl = process.env.REACT_APP_BASE_URL_GET;

export const deployApi = (routedId, serviceId) => {
  return (dispatch) => {
    dispatch(deployApiLoading());
    axios
      .patch(`${url}cmd/api/v1/deploy/${routedId}`)
      .then((resp) => {
        dispatch(deployApiSuccess(resp.data));
        dispatch(Services(serviceId));
        toast.success("Deployed Successfully");
      })
      .catch((error) => {
        dispatch(deployApiFail(error));
        toast.error("Deployment Failed");
      });
  };
};

//post api for create manage apis
export const createManageApi = (data, serviceID) => {
  // http://3.111.184.75:4041/cmd/api/v1/routes/888
  return (dispatch) => {
    dispatch(createManageApiLoading());
    axios
      .post(`${url}cmd/api/v1/routes/${serviceID}`, data)
      .then((resp) => {
        dispatch(createManageApiSuccess(resp.data));
        toast.success("API created successfully");
        window.location.reload()
      })
      .catch((error) => {
        console.log("errordata",error?.response?.data?.detail);
        dispatch(createManageApiFail(error));
        toast.error(error?.response?.data?.detail);
      });
  };
};

export const getServiceList = (id) => {
  return (dispatch) => {
    dispatch(getServiceListLoading());
    axios
      .get(`${getUrl}qry/api/v1/services/${id}`)
      .then((resp) => {
        dispatch(getServiceListSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(getServiceListFail(error));
      });
  };
};
export const editApis = (routeId, data) => {
  return (dispatch) => {
    dispatch(editApiLoading());
    axios
      .patch(`${url}cmd/api/v1/route/${routeId}`, data)
      .then((resp) => {
        dispatch(editApiSuccess(resp.data));
        dispatch(editApiToggle(false))
        toast.success("API Updated Successfully")
      })
      .catch((error) => {
        dispatch(editApiFail(error));
        toast.error("Fail to Update API")
      });
  };
};
export const testApiData = (method, uriData) => {
  return (dispatch) => {
    dispatch(testApiLoading());
    axios
      [method.toLowerCase()](uriData)
      .then((resp) => {
        dispatch(testApiSuccess(resp.data));
        toast.success("API Updated Successfully")
      })
      .catch((error) => {
        dispatch(testApiFail(error));
        toast.error("Fail to Update API")
      });
  };
};
