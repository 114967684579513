export const applicationTableColumns = [
  {
    title: "Application",
    dataIndex: "application",
  },
  {
    title: "Hits",
    dataIndex: "hits",
  },
];

export const pathTableColumns = [
  {
    title: "Path",
    dataIndex: "path",
  },
];

export const slowApplicationTableColumns = [
  {
    title: "Application",
    dataIndex: "application",
  },
  {
    title: "Response Time(ms)",
    dataIndex: "responsetime",
  },
];

export const hitsTableColumns = [
  {
    title: "Host",
    dataIndex: "host",
  },
  {
    title: "Hits",
    dataIndex: "hits",
  },
];

export const applicationTableData = [];
for (let i = 0; i < 6; i++) {
  applicationTableData.push({
    key: i,
    application: `B2B Application ${i}`,
    hits: 32,
  });
}

export const pathTableData = [];
for (let i = 0; i < 6; i++) {
  pathTableData.push({
    key: i,
    path: `api/v2/login/admin/config ${i}`,
  });
}

export const slowApplicationTableData = [];
for (let i = 1; i < 6; i++) {
  slowApplicationTableData.push({
    key: i,
    application: `B2B Application ${i}`,
    responsetime: `${i} . 0${i} `,
  });
}

export const hitsTableData = [];
for (let i = 1; i < 6; i++) {
  hitsTableData.push({
    key: i,
    host: `B2B Application ${i}`,
    hits: `4${i} `,
  });
}
