import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
import { Utils } from "./Utils";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const ScatterChartComponent = (props) => {
  const chartContainer = useRef();
  const ScatterChartData = useSelector(
    (state) => state?.Dashboard?.metrics[0]?.statusCodeData[0]?.series[0]
  );

  const chartData = {
    datasets: [
      {
        label: ScatterChartData?.data[0]?.month,
        data: ScatterChartData?.data?.map((item) => ({
          x: item?.hitCount,
          y: item?.statusCode,
        })),
        borderColor: Utils.CHART_COLORS.red,
        backgroundColor: Utils?.CHART_COLORS?.red,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    aspectRatio: 2,
    maintainAspectRatio: false,
    legend: true,
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const chart = new Chart(chartContainer.current, {
        type: "scatter",
        data: chartData,
        options: chartOptions,
      });

      return () => {
        chart.destroy();
      };
    }
  }, []);

  return (
    <Box w={"100%"} backgroundColor={"white"} p={"20px"} borderRadius={"10px"}>
      <div
        className="card-background"
        style={{ width: "100%", height: "400px" }}
      >
        <canvas
          className="scatter-canvas"
          style={{ width: "100%", height: "400px" }}
          ref={chartContainer}
        />
      </div>
    </Box>
  );
};

export default ScatterChartComponent;
