// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.get span.ant-select-selection-item {
    color: #007f31;
    font-weight: 900;
}
.post span.ant-select-selection-item {
    color: #b38416;
    font-weight: 900;
}
.put span.ant-select-selection-item {
    color: #1863be;
    font-weight: 900;
}
.patch span.ant-select-selection-item {
    color: #6b409d;
    font-weight: 900;
}
.delete span.ant-select-selection-item {
    color: #9a332a;
    font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/manageApi/components/CustomDropDown.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".get span.ant-select-selection-item {\n    color: #007f31;\n    font-weight: 900;\n}\n.post span.ant-select-selection-item {\n    color: #b38416;\n    font-weight: 900;\n}\n.put span.ant-select-selection-item {\n    color: #1863be;\n    font-weight: 900;\n}\n.patch span.ant-select-selection-item {\n    color: #6b409d;\n    font-weight: 900;\n}\n.delete span.ant-select-selection-item {\n    color: #9a332a;\n    font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
