import {
  Box,
  Button
} from "@chakra-ui/react";
import {
  FormControl
} from "@chakra-ui/react";
import { useEffect } from "react";
import ServicesDetails from "./components/ServicesDetails";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ServiceAplication } from "Redux/actions/Services/Services";

export default function Marketplace() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
   dispatch(ServiceAplication())
   localStorage.removeItem("serviceId");
  }, [dispatch])
  
  const onAddService = () => {
    history.push("/admin/new-service")
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <FormControl display="flex" justifyContent="flex-end" marginTop="10px">
        <Button
          onClick={onAddService}
          fontSize='sm'
          variant='brand'
          fontWeight='500'
          w='15%'
          h='50'
          mb='24px'>
          Add Services
        </Button>
      </FormControl>
        <ServicesDetails />
    </Box>
  );
}
